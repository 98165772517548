import * as DOMPurify from 'dompurify';

const sanitizer = function (el, binding, vnode) {

  const handler = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value);
    if (sanitizedValue !== e.target.value) {
      e.target.value = sanitizedValue;
      vnode.elm.dispatchEvent(new CustomEvent('input'));
    }
  };
  el.addEventListener('input', handler);
};

export default sanitizer;
