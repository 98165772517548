export function donwloadFileFromStream(response) {
  const buffer = Buffer.from(response.data, 'binary');
  const mimeType = response.headers['content-type'];
  const contentDisposition = response.headers['content-disposition'];
  let fileName;
  if (contentDisposition) {
    let matched;
    const regex = /^.*filename[^;=\n]*=((['"]).*?\2|[^;\n]*)[\n;]?$/i;
    matched = regex.exec(contentDisposition);
    // on prend matched[1] parce qu'on veut le contenu du premier groupe de capture de la regex
    if (matched && matched[1]) {
      fileName = matched[1];
    }
  }
  // https://developer.mozilla.org/en-US/docs/Web/API/Blob
  const newBlob = new Blob([buffer], { type: mimeType });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // On crée l'objet URL à partir du Blob
  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.download = fileName;
  // On déroule
  link.href = downloadUrl;
  document.body.append(link);
  link.click();
  // Puis on révoque
  setTimeout(() => {
    // Délai nécessaire pour révoquer l'objet
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  }, 100);
}