import userLoginAPI from '@/api/userLoginAPI.js';

/**************** STATE *******************/
const state = {
  isUserAuthenticated: false,
  userData: {
    is_superuser: false
  }
};

/**************** GETTERS *****************/
const getters = {

};

/*************** MUTATIONS ****************/
const mutations = {
  SET_USER_AUTHENTICATION: (state, payload) => {
    state.isUserAuthenticated = payload.authenticated ? payload.authenticated : false;
    if (payload.user) {
      state.userData = payload.user;
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_USER_AUTHENTICATION: async ({ commit }) => {
    const authentication = await userLoginAPI.getUserLogin();
    if (authentication) {
      commit('SET_USER_AUTHENTICATION', authentication);
    } else {
      // redirect to login
      window.location.href = `${process.env.VUE_APP_LOGIN_ROUTE}?next=${window.location.pathname}${window.location.hash}`;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
