import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USER_LOGIN_API_PATH = process.env.VUE_APP_USER_LOGIN_API_PATH;
const LOGIN_API_PATH = process.env.VUE_APP_LOGIN_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const userLoginAPI = {

  async getUserLogin() {
    try {
      const url = new URL(`${i18n.locale}${USER_LOGIN_API_PATH}`, DOMAIN);
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response.data;
      }
    }
    catch (err) {
      if (err.response && err.response.data.code && err.response.data.code === 'terms-required') {
        window.location.href = `${process.env.VUE_APP_LOGIN_TERMS_ROUTE}?next=${window.location.pathname}${window.location.hash}`;
      }
      return false;
    }
  },

  async userLogout() {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, '/signout'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    return response.data;
  }

};

export default userLoginAPI;
