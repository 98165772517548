import axios from 'axios';
import geoserverAPI from '@/api/geoserverAPI.js';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

/**************** STATE *******************/
const state = {
  workspaces: [],
  layers: [],
  layergroups: [],
  selectedWorkspace: null,
};

/**************** GETTERS *****************/
const getters = {};

/*************** MUTATIONS ****************/
const mutations = {
  SET_WORKSPACES: (state, payload) => {
    if (payload.workspaces && payload.workspaces.workspace) {
      state.workspaces = payload.workspaces.workspace.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      state.workspaces = [];
    }
  },

  SET_LAYERS: (state, payload) => {
    if (payload.layers && payload.layers.layer) {
      state.layers = payload.layers.layer.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      state.layers = [];
    }
  },

  SET_LAYERGROUPS: (state, payload) => {
    if (payload.layerGroups && payload.layerGroups.layerGroup) {
      state.layergroups = payload.layerGroups.layerGroup.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      state.layergroups = [];
    }
  },

  SET_SELECTED_WORKSPACE: (state, payload) => {
    state.selectedWorkspace = payload;
  },

};
/**************** ACTIONS *****************/
const actions = {
  GET_WORKSPACES: async ({ commit }) => {
    const workspaces = await geoserverAPI.getWorkspaces();
    commit('SET_WORKSPACES', workspaces);
  },
  GET_LAYERS: async ({ state, commit }) => {
    const layers = await geoserverAPI.getWorkspaceLayers(state.selectedWorkspace.name);
    commit('SET_LAYERS', layers);
  },
  GET_LAYERGROUPS: async ({ state, commit }) => {
    const layergroups = await geoserverAPI.getWorkspaceLayergroups(state.selectedWorkspace.name);
    commit('SET_LAYERGROUPS', layergroups);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
