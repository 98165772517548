import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const usersAPI = {
  async getUsersList(filters, page = 1) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/?page=${page}`), DOMAIN);
    for (const filter in filters) {
      url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async orderUsersList(direction, field, filters, page) {
    let url;
    if (page) {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/?ordering=${direction}${field}&page=${page}`), DOMAIN);
    } else {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/?ordering=${direction}${field}`), DOMAIN);
    }
    for (const filter in filters) {
      url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
    }
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async getUser(id) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async createUser(data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'users/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async updateUser(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async patchUser(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/${id}/`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async addUserToOrganisation(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/${id}/`), DOMAIN);
    const response = await axios.put(url, data);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async deleteUser(id) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return true;
    }
    return false;

  }
};
export default usersAPI;
