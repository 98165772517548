import organisationsAPI from '@/api/organisationsAPI.js';
import { ErrorService } from '@/services/error-service.js';
import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const ORGANISATION_API_PATH = process.env.VUE_APP_ORGANISATION_API_PATH;

/**************** STATE *******************/
const state = {
  currentOrganisation: {},
  organisationsCount: 0,
  organisationsList: [],
  organisationsTypes: [],
  organisationsJuridictions: [],
  organisationsError: null,
  currentOrganisationsOrdering: {
    direction: null,
    field: null
  },
  currentOrganisationsFilters: {
    usergroup__status__in: [2],
    organisation_types: []
  },
  lastCreatedOrganisation: null,
  isOrganisationsListSearched: false,
  searchOrganisationsFilter: null
};

/**************** GETTERS *****************/
const getters = {
  tablePage: (state, getters, rootState) => {
    try {
      return rootState.table.currentTables.find(t => t.name === 'organisationsListTable').currentPage;
    } catch {
      return 1;
    }
  }
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_ORGANISATIONS_LIST: (state, payload) => {
    state.organisationsCount = payload ? payload.count : 0;
    state.organisationsList = payload ? payload.results : [];
  },

  SET_CURRENT_ORGANISATION: (state, payload) => {
    state.currentOrganisation = payload;
  },

  SET_ORGANISATIONS_TYPES: (state, payload) => {
    state.organisationsTypes = payload;
  },

  SET_ORGANISATIONS_JURIDICTIONS: (state, payload) => {
    state.organisationsJuridictions = payload;
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      state.organisationsError = error;
    } else {
      state.organisationsError = error;
    }
  },

  SET_ORGANISATIONS_ORDERING: (state, payload) => {
    state.currentOrganisationsOrdering = payload;
  },

  SET_ORGANISATIONS_FILTERS: (state, payload) => {
    if (payload && (payload.value !== null && payload.value !== undefined)) {
      if (payload.filter === 'organisation_types') {
        state.currentOrganisationsFilters[payload.filter].splice(0, 1, payload.value);
      } else {
        state.currentOrganisationsFilters[payload.filter].push(payload.value);
      }
    }
  },

  REMOVE_ORGANISATIONS_FILTERS: (state, payload) => {
    if (payload && (payload.value !== null && payload.value !== undefined)) {
      state.currentOrganisationsFilters[payload.filter].splice(payload.index, 1);
    }
  },

  SET_LAST_CREATED_ORGANISATION: (state, payload) => {
    state.lastCreatedOrganisation = payload;
  },

  SET_IS_ORGANISATIONSLIST_SEARCHED: (state, payload) => {
    state.isOrganisationsListSearched = payload.isSearched;
    state.searchOrganisationsFilter = payload.text;
  }
};
/**************** ACTIONS *****************/
const actions = {
  PRESET_ORGANISATIONS_FILTERS: ({ state, commit }, filter) => {
    if (state.currentOrganisationsFilters[filter.filter].findIndex(el => el === filter.value) === -1) {
      commit('table/SET_CURRENT_PAGE', { name: 'organisationsListTable', page: 1 }, { root: true });
      commit('SET_ORGANISATIONS_FILTERS', filter);
    }
  },

  PREREMOVE_ORGANISATIONS_FILTERS: ({ state, commit }, filter) => {
    const index = state.currentOrganisationsFilters[filter.filter].findIndex(el => el === filter.value);
    if (index !== -1) {
      commit('table/SET_CURRENT_PAGE', { name: 'organisationsListTable', page: 1 }, { root: true });
      commit('REMOVE_ORGANISATIONS_FILTERS', { index: index, ...filter });
    }
  },

  GET_ORGANISATIONS_LIST: async ({ state, getters, commit, dispatch }, { direction, field, page }) => {
    if (!page) {
      page = getters.tablePage;
    }
    // Save sorting params
    commit('SET_ORGANISATIONS_ORDERING', { direction, field });

    if (state.isOrganisationsListSearched) {
      dispatch('SEARCH_ORGANISATIONS_LIST', { text: state.searchOrganisationsFilter });
    } else if (field) {
      await organisationsAPI.orderOrganisationsList(direction, field, state.currentOrganisationsFilters, page)
        .then((organisations) => {
          if (organisations) {
            commit('SET_ERROR', null);
            commit('SET_ORGANISATIONS_LIST', organisations);
            commit('SET_IS_ORGANISATIONSLIST_SEARCHED', {
              isSearched: false,
              text: null
            });
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    } else {
      await organisationsAPI.getOrganisationsList(state.currentOrganisationsFilters, page)
        .then((organisations) => {
          if (organisations) {
            commit('SET_ERROR', null);
            commit('SET_ORGANISATIONS_LIST', organisations);
            commit('SET_IS_ORGANISATIONSLIST_SEARCHED', {
              isSearched: false,
              text: null
            });
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    }
  },

  GET_ORGANISATION_DETAIL: async ({ commit }, id) => {
    await organisationsAPI.getOrganisation(id)
      .then((organisation) => {
        if (organisation) {
          commit('SET_ERROR', null);
          commit('SET_CURRENT_ORGANISATION', organisation);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  GET_ORGANISATIONS_TYPES: async ({ commit }) => {
    await organisationsAPI.getOrganisationsTypes()
      .then((types) => {
        if (types) {
          commit('SET_ERROR', null);
          commit('SET_ORGANISATIONS_TYPES', types);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  GET_ORGANISATIONS_JURIDICTIONS: async ({ commit }) => {
    await organisationsAPI.getOrganisationsJuridictions()
      .then((juridictions) => {
        if (juridictions) {
          commit('SET_ERROR', null);
          commit('SET_ORGANISATIONS_JURIDICTIONS', juridictions);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  SEARCH_ORGANISATIONS_LIST: async ({ state, commit, dispatch }, { text, ordering, unfiltered = false }) => {
    if (!ordering) {
      ordering = state.currentOrganisationsOrdering;
    }
    if (text) {
      const searched = await dispatch('HANDLE_SEARCH_REQUEST', { text, ordering, unfiltered });
      return searched;
    } else {
      commit('SET_IS_ORGANISATIONSLIST_SEARCHED', {
        isSearched: false,
        text: null
      });
      await dispatch('GET_ORGANISATIONS_LIST', { ...ordering });
      return true;
    }
  },

  HANDLE_SEARCH_REQUEST: async ({ state, rootState, getters, commit }, { text, ordering, unfiltered = false }) => {
    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_organisations_list', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_organisations_list',
      controller: controller
    }, { root: true });

    let url;
    if (ordering.field) {
      url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/?ordering=${ordering.direction}${ordering.field}&page=${getters.tablePage}&search=${text}`), DOMAIN);
    } else {
      url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/?page=${getters.tablePage}&search=${text}`), DOMAIN);
    }

    // Add filters to url
    const filters = state.currentOrganisationsFilters;
    if (!unfiltered) {
      for (const filter in filters) {
        url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
      }
    }

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const organisations = response.data;
        if (organisations) {
          commit('SET_ERROR', null);
          commit('SET_ORGANISATIONS_LIST', organisations);
          commit('SET_IS_ORGANISATIONSLIST_SEARCHED', {
            isSearched: true,
            text: text
          });
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_organisations_list', { root: true });
        return true;
      }
      return false;
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('SET_ERROR', err);
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_organisations_list', { root: true });
        return true;
      }
      return false;
    }
  },

  CREATE_ORGANISATION: async ({ commit }, data) => {
    await organisationsAPI.createOrganisation(data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(resp, 'L\'organisation a été créée avec succès.');
          commit('SET_LAST_CREATED_ORGANISATION', resp);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  UPDATE_ORGANISATION: async ({ commit }, { id, data }) => {
    await organisationsAPI.updateOrganisation(id, data).then((resp) => {
      if (resp) {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(resp, 'L\'organisation a été modifiée avec succès.');
      }
    }).catch((error) => {
      commit('SET_ERROR', error);
    });
  },

  REMOVE_USER_FROM_ORGANISATION: async ({ commit }, { usergroupId, userId }) => {
    const response = await organisationsAPI.removeUserFromOrganisation(usergroupId, userId);
    if (response) {
      commit('SET_ERROR', null);
      ErrorService.onSuccess(response, 'L\'utilisateur a bien été retiré de l\'organisation');
    }
  },

  SET_ORGANISATION_THUMBNAIL: async ({ commit }, { id, data }) => {
    await organisationsAPI.setOrganisationThumbnail(id, data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  SET_ORGANISATION_AGREEMENT: async ({ commit }, { id, data }) => {
    await organisationsAPI.setOrganisationAgreement(id, data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  DELETE_ORGANISATION: async ({ commit }, id) => {
    await organisationsAPI.deleteOrganisation(id)
      .then(() => {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(true, 'L\'organisation a été supprimée avec succès.');
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
