import usersAPI from '@/api/usersAPI.js';
import usergroupsAPI from '@/api/usergroupsAPI.js';

import { ErrorService } from '@/services/error-service.js';

// MUTATIONS
export const SET_DEMANDES_USERS_LIST = 'SET_DEMANDES_USERS_LIST';
export const SET_DEMANDES_USERGROUPS_LIST = 'SET_DEMANDES_USERGROUPS_LIST';
export const SET_ERROR = 'SET_ERROR';
export const SET_USERS_DEMANDES_FILTERS = 'SET_USERS_DEMANDES_FILTERS';
export const SET_USERGROUPS_DEMANDES_FILTERS = 'SET_USERGROUPS_DEMANDES_FILTERS';
export const REMOVE_USERS_DEMANDES_FILTERS = 'REMOVE_USERS_DEMANDES_FILTERS';
export const REMOVE_USERGROUPS_DEMANDES_FILTERS = 'REMOVE_USERGROUPS_DEMANDES_FILTERS';

// ACTIONS
export const GET_USERS_DEMANDES_LIST = 'GET_USERS_DEMANDES_LIST';
export const GET_USERGROUPS_DEMANDES_LIST = 'GET_USERGROUPS_DEMANDES_LIST';

/**************** STATE *******************/
const state = {
  usersDemandesCount: 0,
  usergroupsDemandesCount: 0,
  usersDemandesList: [],
  usergroupsDemandesList: [],
  demandesError: null,
  currentUsersDemandesFilters: {
    status__in: ['1']
  },
  currentUsergroupsDemandesFilters: {
    status__in: ['1']
  }
};

/**************** GETTERS *****************/
const getters = {
};

/*************** MUTATIONS ****************/
const mutations = {
  [SET_DEMANDES_USERS_LIST]: (state, payload) => {
    if (payload && payload.results) {
      state.usersDemandesList = payload.results;
    }
    if (payload && payload.count) {
      state.usersDemandesCount = payload.count;
    }
  },

  [SET_DEMANDES_USERGROUPS_LIST]: (state, payload) => {
    if (payload && payload.results) {
      state.usergroupsDemandesList = payload.results;
    }
    if (payload && payload.count) {
      state.usergroupsDemandesCount = payload.count;
    }
  },

  [SET_ERROR]: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      state.demandesError = error.response.data.detail;
    } else {
      state.demandesError = error;
    }
  },

  [SET_USERS_DEMANDES_FILTERS]: (state, payload) => {
    if (state.currentUsersDemandesFilters[payload.filter].findIndex(el => el === payload.value) === -1) {
      state.currentUsersDemandesFilters[payload.filter].push(payload.value);
    }
  },

  [SET_USERGROUPS_DEMANDES_FILTERS]: (state, payload) => {
    if (state.currentUsergroupsDemandesFilters[payload.filter].findIndex(el => el === payload.value) === -1) {
      state.currentUsergroupsDemandesFilters[payload.filter].push(payload.value);
    }
  },

  [REMOVE_USERS_DEMANDES_FILTERS]: (state, payload) => {
    const index = state.currentUsersDemandesFilters[payload.filter].findIndex(el => el === payload.value);
    if (index !== -1) {
      state.currentUsersDemandesFilters[payload.filter].splice(index, 1);
    }
  },

  [REMOVE_USERGROUPS_DEMANDES_FILTERS]: (state, payload) => {
    const index = state.currentUsergroupsDemandesFilters[payload.filter].findIndex(el => el === payload.value);
    if (index !== -1) {
      state.currentUsergroupsDemandesFilters[payload.filter].splice(index, 1);
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
  [GET_USERS_DEMANDES_LIST]: async ({ state, commit }, { direction, field }) => {
    const filters = state.currentUsersDemandesFilters;
    if (field) {
      await usersAPI.orderUsersList(direction, field, filters)
        .then((users) => {
          if (users) {
            commit('SET_ERROR', null);
            commit('SET_DEMANDES_USERS_LIST', users);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    } else {
      await usersAPI.getUsersList(filters)
        .then((users) => {
          if (users) {
            commit('SET_ERROR', null);
            commit('SET_DEMANDES_USERS_LIST', users);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    }
  },

  [GET_USERGROUPS_DEMANDES_LIST]: async ({ state, commit }, { direction, field }) => {
    const filters = state.currentUsergroupsDemandesFilters;
    if (field) {
      await usergroupsAPI.orderUsergroupsList(direction, field, [], null, filters)
        .then((usergroups) => {
          if (usergroups) {
            commit('SET_ERROR', null);
            commit('SET_DEMANDES_USERGROUPS_LIST', usergroups);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    } else {
      await usergroupsAPI.getUsergroupsList([], null, filters)
        .then((usergroups) => {
          if (usergroups) {
            commit('SET_ERROR', null);
            commit('SET_DEMANDES_USERGROUPS_LIST', usergroups);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
