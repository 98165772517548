/**************** STATE *******************/
const state = {
  currentTables: [
    /*{
      name: null,
      loading: false,
      currentPage: 1,
      isTableSearched: false,
      searchQuery: null
    }*/
  ]
};

/**************** GETTERS *****************/
const getters = {};

/*************** MUTATIONS ****************/
const mutations = {
  SET_TABLE: (state, { name, table }) => {
    try {
      const index = state.currentTables.findIndex(t => t.name === name);
      if (index === -1) {
        state.currentTables.push({
          name: name,
          ...table
        });
      } else {
        state.currentTables[index] = {
          name: name,
          ...table
        };
      }
    } catch (err) {
      console.error(err);
    }
  },

  SET_TABLE_LOADER: (state, { name, loading }) => {
    try {
      const index = state.currentTables.findIndex(t => t.name === name);
      if (index !== -1) {
        const currentTable = state.currentTables[index];
        state.currentTables.splice(index, 1, {
          ...currentTable,
          loading: loading
        });
      }
    } catch (err) {
      console.error(err);
    }
  },

  SET_CURRENT_PAGE: (state, { name, page }) => {
    try {
      const index = state.currentTables.findIndex(t => t.name === name);
      if (index !== -1) {
        const currentTable = state.currentTables[index];
        state.currentTables.splice(index, 1, {
          ...currentTable,
          currentPage: page
        });
      }
    } catch (err) {
      console.error(err);
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
