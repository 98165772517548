import axios from 'axios';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const DOMAIN = process.env.VUE_APP_DOMAIN;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const miscAPI = {

  async searchKeywords(query) {
    try {
      const url = new URL(`taggit/tags/?search=${query}`, DOMAIN);
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response.data;
      }
    }
    catch (err) {
      return false;
    }
  },

  async getSchedulers() {
    const url = new URL('schedulers/', DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  }
};

export default miscAPI;
