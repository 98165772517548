import store from '@/store';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function renameObjKey(o, oldKey, newKey) {
  Object.defineProperty(o, newKey,
    Object.getOwnPropertyDescriptor(o, oldKey)
  );
  delete o[oldKey];
}

export function resolve(from, to) {
  const resolvedUrl = new URL(to, new URL(from, 'resolve://'));
  if (resolvedUrl.protocol === 'resolve:') {
    // `from` is a relative URL.
    const { pathname, search, hash } = resolvedUrl;
    return pathname + search + hash;
  }
  return resolvedUrl.toString();
}

export function fileConvertSize(aSize){
  aSize = Math.abs(parseInt(aSize, 10));
  const def = [[1, 'octets', 0], [1024, 'ko', 0], [1024*1024, 'Mo', 1], [1024*1024*1024, 'Go', 2], [1024*1024*1024*1024, 'To', 2]];
  for (let i=0; i<def.length; i++) {
    if (aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(def[i-1][2]) + ' ' + def[i-1][1];
  }
}

export function slugify(string) {
  return string
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/gi, '')
    .replace(/[^A-Z0-9]/gi, '-')
    .replace(/[^A-Z0-9]+$/gi, '')
    .replace(/^[^A-Z0-9]+/gi, '')
    .toLowerCase();
}

export function usernamify(string) {
  return string
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/gi, '')
    .replace(/\s/g, '')
    .toLowerCase();
}

export function getUploadProgress(progressEvent) {
  if (progressEvent.event.lengthComputable) {
    const totalLength =
      progressEvent.event.lengthComputable ?
        progressEvent.event.total :
        progressEvent.event.target.getResponseHeader('content-length') || progressEvent.event.target.getResponseHeader('x-decompressed-content-length');
    if (totalLength !== null) {
      store.commit('SET_PROGRESS_PERCENTAGE', (Math.round( (progressEvent.event.loaded * 100) / totalLength )));
    }
  }
}
