<template>
  <b-modal
    id="progress-modal"
    v-model="openModal"
    size="md"
    :title="$t('modals.progress.title')"
    :hide-footer="true"
    :hide-header-close="true"
  >
    <div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
      >
        <div
          id="modal-progress-bar"
          class="progress"
          role="progressbar"
          aria-label="Progress bar"
          :aria-valuenow="progressPercentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            :class="{ notransition: progressPercentage === 0 }"
            class="progress-bar progress-bar-striped progress-bar-animated"
            :style="{ width: `${progressPercentage}%` }"
          >
            {{ `${progressPercentage}%` }}
          </div>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ConfirmationModal',

  data() {
    return {
      openModal: false,
      loading: false
    };
  },

  computed: {
    ...mapState([
      'progressPercentage'
    ]),
    ...mapState('modal', [
      'progress'
    ])
  },

  watch: {
    'progress.open': function(newValue) {
      this.resetModal();
      this.openModal = newValue;
    },

    progressPercentage(newValue) {
      if (newValue === 100) {
        this.SET_PROGRESS_PERCENTAGE(0);
        this.CLOSE_MODAL('progress');
      }
    }
  },

  methods: {
    ...mapMutations([
      'SET_PROGRESS_PERCENTAGE'
    ]),
    ...mapMutations('modal', [
      'CLOSE_MODAL',
    ]),
    
    resetModal() {
      this.openModal = false;
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="less">
#modal-progress-bar {
  width: 100%;
  height: 1rem;
  margin: 1rem 0;
  .progress-bar {
    background-color: @blue;
  }
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>
