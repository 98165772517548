<template>
  <div id="app-footer">
    <span>
      <div style="text-align: center;">
        {{ $t('words.poweredBy') }}
        <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">
          Neogeo-Technologies
        </a>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
a {
  color: #fff;
  font-weight: 600;
}
</style>
