/**************** STATE *******************/
const state = {};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {};

/**************** ACTIONS *******************/
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};