<template>
  <div class="locale-changer">
    <div
			:class="$i18n.locale"
			class="selected-lang"
		>
			{{ $i18n.locale.toUpperCase() }}
		</div>
    <ul>
      <li
				v-for="(lang, i) in langs.filter(el => el !== $i18n.locale)"
				:key="`lang-${i}`"
				@click="changeLocale(lang)"
			>
				{{ lang.toUpperCase() }}
      </li>
    </ul> 
  </div>
</template>

<script>

export default {
  name: 'LocaleChanger',

  data () {
    return {
      langs: ['fr', 'en'],
    }
  },

  watch: {
    '$i18n.locale': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        const to = this.$router.resolve({
          params: { locale: newValue },
          query: this.$route.query
        });
        this.$router.push(to.location);
      }
    },
  },

  methods: {
    changeLocale(e) {
			this.$i18n.locale = e;
		}
  }

}
</script>

<style lang="less" scoped>

.locale-changer {
  width: 50px;
	height: 90%;
	display: flex;   
	align-items: center;
  justify-content: center;
  
  .selected-lang {
    display: flex;   
		align-items: center;
    justify-content: center;
    cursor: pointer;
		color: #fff;
		font-weight: bold;
  }

	ul {
		// z-index: 9999;
	  margin: 0;
	  padding: 0;
	  display: none;
	  background-color: #fff;
	  position: absolute;
	  top: 45px;
	  right: 0px;
	  width: 50px;
	  border-radius: 5px;
	  box-shadow: 0px 1px 10px rgba(0,0,0,0.2);

		li {
			// position: relative;
			// z-index: 9999;
		  list-style: none;
		  text-align: left;
		  display: flex;
		  justify-content: space-between;
			text-decoration: none;
			width: 50px;
			padding: 5px 10px;
			display: block;
			font-weight: bold;
			border-radius: 5px;
		}

		li:hover {
			cursor: pointer;
			// position: relative;
			// z-index: 9999;
		  background-color: @blue;
			color: #fff;
		}

	}
}

.locale-changer:hover ul {
  display: block;
}

</style>
