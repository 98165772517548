import router from '@/router';
import usergroupsAPI from '@/api/usergroupsAPI.js';
import datasetsAPI from '@/api/datasetsAPI';
import { ErrorService } from '@/services/error-service.js';
import i18n from '@/i18n';

import axios from 'axios';
const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};
const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;
const DATASET_API_PATH = process.env.VUE_APP_DATASET_API_PATH;

const USERGROUP_TYPES = {
  organisation: 'organisation',
  group: 'user-group',
  sphere : 'group-of-organisation'
};

/**************** STATE *******************/
const state = {
  usergroupsTypes: [],
  usergroupsCount: 0,
  usergroupsList: [],
  spheresCount: 0,
  spheres: [],
  groupsCount: 0,
  groups: [],
  usergroupsRoles: [],
  currentUsergroup: null,
  currentUsergroupMembers: [],
  currentUsergroupMembersCount: null,
  currentUsergroupChildren: [],
  currentUsergroupChildrenCount: null,
  usergroupsError: null,
  lastCreatedUsergroup: null,
  isGroupsSearched: false,
  isSpheresSearched: false,
  currentUsergroupsFilters: {
    usergroup__status__in: [],
    user__role__in: []
  },
  contactsCount: 0,
  contactsList: [],
  searchContactsFilter: null,
  isContactsListSearched: null,
  usergroupStatuses: []
};

/**************** GETTERS *****************/
const getters = {
  groupsTablePage: (state, getters, rootState) => {
    try {
      return rootState.table.currentTables.find(t => t.name === 'groupsListTable').currentPage;
    } catch {
      return 1;
    }
  },
  spheresTablePage: (state, getters, rootState) => {
    try {
      return rootState.table.currentTables.find(t => t.name === 'spheresListTable').currentPage;
    } catch {
      return 1;
    }
  }
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_USERGROUPS_TYPES: (state, payload) => {
    state.usergroupsTypes = payload;
  },

  SET_USERGROUPS_LIST: (state, payload) => {
    if (payload) {
      state.usergroupsCount = payload.count;
      state.usergroupsList = payload.results;
    }
  },

  SET_USERGROUPS_GROUPS_LIST: (state, payload) => {
    if (payload) {
      state.groupsCount = payload.count;
      state.groups = payload.results;
    }
  },

  SET_USERGROUPS_SPHERES_LIST: (state, payload) => {
    if (payload) {
      state.spheresCount = payload.count;
      state.spheres = payload.results;
    }
  },

  SET_CURRENT_USERGROUP: (state, payload) => {
    state.currentUsergroup = payload;
  },

  SET_CURRENT_USERGROUP_MEMBERS: (state, payload) => {
    state.currentUsergroupMembersCount = payload.count;
    state.currentUsergroupMembers = payload.results;
  },

  SET_CURRENT_USERGROUP_CHILDREN: (state, payload) => {
    state.currentUsergroupChildrenCount = payload.count;
    state.currentUsergroupChildren = payload.results;
  },

  SET_USERGROUPS_ROLES: (state, payload) => {
    state.usergroupsRoles = payload;
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      state.usergroupsError = error;
    } else {
      state.usergroupsError = error;
    }
  },

  SET_LAST_CREATED_USERGROUP: (state, payload) => {
    state.lastCreatedUsergroup = payload;
  },

  SET_IS_GROUPS_SEARCHED: (state, payload) => {
    state.isGroupsSearched = payload;
  },

  SET_IS_SPHERES_SEARCHED: (state, payload) => {
    state.isSpheresSearched = payload;
  },

  SET_USERGROUPS_FILTERS: (state, payload) => {
    const index = state.currentUsergroupsFilters[payload.filter].findIndex(el => el === payload.value);
    if (index === -1) {
      state.currentUsergroupsFilters[payload.filter].push(payload.value);
    }
  },

  REMOVE_USERGROUPS_FILTERS: (state, payload) => {
    state.currentUsergroupsFilters[payload.filter.filter].splice(payload.index, 1);
  },

  RESET_USERGROUPS_FILTERS: (state) => {
    state.currentUsergroupsFilters = {
      usergroup__status__in: [],
      user__role__in: []
    };
  },

  SET_CONTACTS_LIST: (state, payload) => {
    state.contactsCount = payload.count;
    state.contactsList = payload.results;
  },

  SET_IS_CONTACTS_LIST_SEARCHED: (state, payload) => {
    state.isContactsListSearched = payload.isSearched;
    state.searchContactsFilter = payload.text;
  },

  SET_USERGROUP_STATUSES: (state, payload) => {
    if (payload && payload.length) {
      state.usergroupStatuses = [...payload];
    } else {
      state.usergroupStatuses = [];
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_USERGROUPS_TYPES: async({ commit }) => {
    const usergroupTypes = await usergroupsAPI.getUsergroupsTypes();
    commit('SET_USERGROUPS_TYPES', usergroupTypes);
  },
  GET_USERGROUP_STATUSES: async ({ commit }) => {
    try {
      const usergroupStatuses = await usergroupsAPI.getUsergroupStatuses();
      commit('SET_USERGROUP_STATUSES', usergroupStatuses);
    } catch {
      return;
    }
  },

  GET_USERGROUPS_LIST: async ({ state, commit }, { direction, field, usergroupTypes, page }) => {
    if (!page) {
      page = 1;
    }
    if (field) {
      await usergroupsAPI.orderUsergroupsList(direction, field, usergroupTypes, page, state.currentUsergroupsFilters)
        .then((usergroups) => {
          if (usergroups) {
            commit('SET_ERROR', null);
            commit('SET_USERGROUPS_LIST', usergroups);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    } else {
      await usergroupsAPI.getUsergroupsList(usergroupTypes, page, state.currentUsergroupsFilters)
        .then((usergroups) => {
          if (usergroups) {
            commit('SET_ERROR', null);
            commit('SET_USERGROUPS_LIST', usergroups);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    }
  },

  GET_USERGROUPS_GROUPS_LIST: async ({ state, commit, getters }, { direction, field, page }) => {
    if (!page) {
      page = getters.groupsTablePage;
    }
    if (field) {
      await usergroupsAPI.orderFilteredUsergroupsList(
        direction, field, USERGROUP_TYPES.group, page, state.currentUsergroupsFilters
      )
        .then((groups) => {
          if (groups) {
            commit('SET_ERROR', null);
            commit('SET_USERGROUPS_GROUPS_LIST', groups);
            commit('SET_IS_GROUPS_SEARCHED', false);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    } else {
      await usergroupsAPI.getFilteredUsergroupsList(USERGROUP_TYPES.group, page, state.currentUsergroupsFilters)
        .then((groups) => {
          if (groups) {
            commit('SET_ERROR', null);
            commit('SET_USERGROUPS_GROUPS_LIST', groups);
            commit('SET_IS_GROUPS_SEARCHED', false);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    }
  },

  GET_USERGROUPS_SPHERES_LIST: async ({ state, commit, getters }, { direction, field, page }) => {
    if (!page) {
      page = getters.spheresTablePage;
    }
    if (field) {
      await usergroupsAPI.orderFilteredUsergroupsList(
        direction, field, USERGROUP_TYPES.sphere, page, state.currentUsergroupsFilters
      )
        .then((spheres) => {
          if (spheres) {
            commit('SET_ERROR', null);
            commit('SET_USERGROUPS_SPHERES_LIST', spheres);
            commit('SET_IS_SPHERES_SEARCHED', false);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    } else {
      await usergroupsAPI.getFilteredUsergroupsList(USERGROUP_TYPES.sphere, page, state.currentUsergroupsFilters)
        .then((spheres) => {
          if (spheres) {
            commit('SET_ERROR', null);
            commit('SET_USERGROUPS_SPHERES_LIST', spheres);
            commit('SET_IS_SPHERES_SEARCHED', false);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
        });
    }
  },

  SEARCH_USERGROUPS_LIST: async ({ dispatch }, { text, types, direction = '', field }) => {

    if (types.length === 0) {
      if (router.currentRoute.name === 'Groups') {
        types.push(USERGROUP_TYPES.group);
      } else if (router.currentRoute.name === 'Spheres') {
        types.push(USERGROUP_TYPES.sphere);
      }
    }

    if (text) {
      const searched = await dispatch('HANDLE_SEARCH_REQUEST', { text, types });
      return searched;
    } else {
      if (types.length === 1 && types[0] === USERGROUP_TYPES.sphere) {
        await dispatch('GET_USERGROUPS_SPHERES_LIST', {
          direction: direction,
          field: field
        });
      } else if (types.length === 1 && types[0] === USERGROUP_TYPES.group) {
        await dispatch('GET_USERGROUPS_GROUPS_LIST', {
          direction: direction,
          field: field
        });
      } else {
        await dispatch('GET_USERGROUPS_LIST', {
          direction: direction,
          field: field,
          usergroupTypes: types,
          page: 1
        });
      }
      return true;
    }
  },

  HANDLE_SEARCH_REQUEST: async ({ state, rootState, getters, commit }, { text, types }) => {
    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_usergroups_list', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_usergroups_list',
      controller: controller
    }, { root: true });

    let page = 1;
    if (types && types.length === 1 && types[0] === 'user-group') {
      page = getters.groupsTablePage;
    }
    if (types && types.length === 1 && types[0] === 'group-of-organisation') {
      page = getters.groupsTablePage;
    }
    
    let url = new URL(path.join(
      `${i18n.locale}${USERGROUP_API_PATH}`,
      `user-groups/?page=${page}&search=${text}&usergroup_types=${types.join(',')}`
    ), DOMAIN);
    for (const filter in state.currentUsergroupsFilters) {
      if (state.currentUsergroupsFilters[filter] && state.currentUsergroupsFilters[filter].length) {
        url.href = url.href.concat('', `&${filter}=${state.currentUsergroupsFilters[filter].join(',')}`);
      }
    }

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const usergroups = response.data;
        if (usergroups) {
          commit('SET_ERROR', null);
          if (types.length === 1 && types[0] === USERGROUP_TYPES.sphere) {
            commit('SET_USERGROUPS_SPHERES_LIST', usergroups);
            commit('SET_IS_SPHERES_SEARCHED', true);
          } else if (types.length === 1 && types[0] === USERGROUP_TYPES.group) {
            commit('SET_USERGROUPS_GROUPS_LIST', usergroups);
            commit('SET_IS_GROUPS_SEARCHED', true);
          } else {
            commit('SET_USERGROUPS_LIST', usergroups);
          }
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_usergroups_list', { root: true });
        return true;
      }
      return false;
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('SET_ERROR', err);
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_usergroups_list', { root: true });
        return true;
      }
      return false;
    }
  },

  GET_USERGROUP_DETAIL: async ({ commit }, id) => {
    await usergroupsAPI.getUsergroup(id)
      .then((usergroup) => {
        if (usergroup) {
          commit('SET_ERROR', null);
          commit('SET_CURRENT_USERGROUP', usergroup);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  GET_USERGROUP_MEMBERS: async ({ state, commit }, { id, direction, field, page }) => {
    if (!id) {
      id = state.currentUsergroup.id;
    }
    await usergroupsAPI.getUsergroupMembers(id, direction, field, page)
      .then((members) => {
        if (members) {
          commit('SET_ERROR', null);
          commit('SET_CURRENT_USERGROUP_MEMBERS', members);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  GET_USERGROUP_CHILDREN: async ({ state, commit }, { id, direction, field }) => {
    if (!id) {
      id = state.currentUsergroup.id;
    }
    await usergroupsAPI.getUsergroupChildren(id, direction, field)
      .then((children) => {
        if (children) {
          commit('SET_ERROR', null);
          commit('SET_CURRENT_USERGROUP_CHILDREN', children);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  GET_USERGROUPS_ROLES: async ({ commit }) => {
    await usergroupsAPI.getUsergroupRoles()
      .then((roles) => {
        if (roles) {
          commit('SET_ERROR', null);
          commit('SET_USERGROUPS_ROLES', roles);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  UPDATE_USERGROUP: async ({ commit }, { id, data, usergroupType = 'user-group' }) => {
    await usergroupsAPI.updateUsergroup(id, data)
      .then((usergroup) => {
        if (usergroup) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(usergroup, i18n.messages[i18n.locale].messages.success[usergroupType].update);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  PATCH_USERGROUP: async ({ commit }, { id, data, usergroupType = 'organisation' }) => {
    await usergroupsAPI.patchUsergroup(id, data).then((resp) => {
      if (resp) {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(resp, i18n.messages[i18n.locale].messages.success[usergroupType].update);
      }
    }).catch((error) => {
      commit('SET_ERROR', error);
    });
  },

  CREATE_USERGROUP: async ({ commit }, { data, usergroupType = 'user-group' }) => {
    await usergroupsAPI.createUsergroup(data)
      .then((usergroup) => {
        if (usergroup) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(usergroup, i18n.messages[i18n.locale].messages.success[usergroupType].create);
          commit('SET_LAST_CREATED_USERGROUP', usergroup);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  DELETE_USERGROUP: async ({ commit }, { id, usergroupType = 'user-group' }) => {
    await usergroupsAPI.deleteUsergroup(id)
      .then(() => {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(true, i18n.messages[i18n.locale].messages.success[usergroupType].delete);
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  GET_CONTACTS_LIST: async ({ state, commit, dispatch }, { usergroupId, page } ) => {
    if (state.isContactsListSearched) {
      dispatch('SEARCH_CONTACTS_LIST', { text: state.searchContactsFilter, page: page });
    } else {
      const contacts = await datasetsAPI.getContacts(usergroupId, page);
      commit('SET_CONTACTS_LIST', contacts);
    }
  },

  SEARCH_CONTACTS_LIST: async ({ state, commit, dispatch }, { text, page }) => {
    if (text) {
      const searched = await dispatch('HANDLE_CONTACTS_SEARCH_REQUEST', { text, page });
      return searched;
    } else {
      commit('SET_IS_CONTACTS_LIST_SEARCHED', {
        isSearched: false,
        text: null
      });
      await dispatch('GET_CONTACTS_LIST', {
        usergroupId: state.currentUsergroup.id,
        page: page
      });
      return true;
    }
  },

  HANDLE_CONTACTS_SEARCH_REQUEST: async ({ state, rootState, commit }, { text, page }) => {
    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_contacts_list', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_contacts_list',
      controller: controller
    }, { root: true });

    let url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `contacts/?usergroup_id=${state.currentUsergroup.id}&search=${text}`), DOMAIN);
    if (page) {
      url = url + `&page=${page}`;
    }

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const contacts = response.data;
        if (contacts) {
          commit('SET_ERROR', null);
          commit('SET_CONTACTS_LIST', contacts);
          commit('SET_IS_CONTACTS_LIST_SEARCHED', {
            isSearched: true,
            text: text
          });
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_contacts_list', { root: true });
        return true;
      }
      return false;
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('SET_ERROR', err);
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_contacts_list', { root: true });
        return true;
      }
      return false;
    }
  },

  SEARCH_CONTACTS: async ({ rootState, commit }, { text, usergroups }) => {
    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_contacts', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_contacts',
      controller: controller
    }, { root: true });

    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `contacts/?search=${text}&usergroup_id=${usergroups.join(',')}`), DOMAIN);

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const contacts = response.data;
        if (contacts) {
          commit('SET_ERROR', null);
          commit('SET_CONTACTS_LIST', contacts);
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_contacts', { root: true });
      }
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('SET_ERROR', err);
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_contacts', { root: true });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
