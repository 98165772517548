import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

const state = {
  abortControllers: [],
  reloadIntervalIds: {},
  progressPercentage: 0
};

const mutations = {
  SET_ABORT_CONTROLLER: (state, { id, controller }) => {
    const index = state.abortControllers.findIndex(el => el.id === id);
    if (index !== -1) {
      state.abortControllers.splice(index, 1, {
        id: id,
        controller: controller
      });
    } else {
      state.abortControllers.push({
        id: id,
        controller: controller
      });
    }
  },
  REMOVE_ABORT_CONTROLLER: (state, id) => {
    const index = state.abortControllers.findIndex(el => el.id === id);
    if (index !== -1) {
      state.abortControllers.splice(index, 1);
    }
  },
  RESET_ABORT_CONTROLLERS: (state) => {
    state.abortControllers = [];
  },
  USE_ABORT_CONTROLLER: (state, id) => {
    if (state.abortControllers.find(el => el.id === id)) {
      state.abortControllers.find(el => el.id === id).controller.abort();
    }
  },

  SET_RELOAD_INTERVAL_ID: (state, payload) => {
    state.reloadIntervalIds[payload.name] = payload.interval;
  },

  CLEAR_RELOAD_INTERVAL_ID: (state, name) => {
    clearInterval(state.reloadIntervalIds[name]);
    state.reloadIntervalIds[name] = null;
  },

  SET_PROGRESS_PERCENTAGE: (state, payload) => {
    state.progressPercentage = payload;
  }
};

export default new Vuex.Store({
  modules,
  state,
  mutations
});
