import axios from 'axios';
import i18n from '@/i18n';
import { getUploadProgress } from '@/utils';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const RESOURCE_API_PATH = process.env.VUE_APP_RESOURCE_API_PATH;
const GEOSPATIAL_API_PATH = process.env.VUE_APP_GEOSPATIAL_API_PATH;
const DATASET_API_PATH = process.env.VUE_APP_DATASET_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const resourcesAPI = {

  async getResourcesList(filters, page = 1) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/?page=${page}`), DOMAIN);
    for (const filter in filters) {
      url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResource(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceDatasetRelation(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async getResourceToDatasetScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async buildResourceIndex(id, scenarioId, asynchrone = true) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/_scenarios/${scenarioId}/run/?asynchrone=${asynchrone}`), DOMAIN);
    const response = await axios.post(url, null, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  async patchResource(id, data, datasetId, withoutActions) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      if (!withoutActions) {
        const url2 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=update_or_create_and_push_record&asynchrone=false`), DOMAIN);
        await axios.patch(url2, {}, { ...DEV_AUTH && { auth: AUTH } });
        const url3 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=build_elastic_index&asynchrone=true`), DOMAIN);
        await axios.patch(url3, {}, { ...DEV_AUTH && { auth: AUTH } });
      }
      return response.data;
    }
    return false;
  },

  async patchResourceGeographicLayer(url, data, datasetId, withoutActions) {
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      if (!withoutActions) {
        const url2 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=update_or_create_and_push_record&asynchrone=false`), DOMAIN);
        await axios.patch(url2, {}, { ...DEV_AUTH && { auth: AUTH } });
        const url3 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=build_elastic_index&asynchrone=true`), DOMAIN);
        await axios.patch(url3, {}, { ...DEV_AUTH && { auth: AUTH } });
      }
      return response.data;
    }
    return false;
  },

  async getResourceGeographicLayerScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${GEOSPATIAL_API_PATH}`, `/geographiclayer/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async runResourceGeographicLayerScenario(id, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${GEOSPATIAL_API_PATH}`, `/geographiclayer/${id}/_scenarios/${scenarioId}/run/?asynchrone=false`), DOMAIN);
    const response = await axios.post(url, null, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  async deleteResourceToDataset(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async deleteResource(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async updateResource(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceDataFormats() {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'data-format/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceData(location) {
    const url = new URL(location, DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceExecutionFrequency(location) {
    const url = new URL(location, DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getFTPChoices(usergroupId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp-choices/?usergroup_id=${usergroupId}`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceKinds() {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'kinds/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },


  // File Upload requests

  async createFileUploadFormat(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'file-upload/'), DOMAIN);
    const response = await axios.post(url, { dataformat_id: data.dataformat_id }, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async createFileUploadFile(id, file) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/`), DOMAIN);
    const response = await axios.put(url, file, {
      ...DEV_AUTH && { auth: AUTH },
      onUploadProgress: (progressEvent) => { getUploadProgress(progressEvent); }
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileUploadFormat(location, data) {
    const url = new URL(location, DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileUploadFile(location, file) {
    const url = new URL(location, DOMAIN);
    const response = await axios.put(url, file, {
      ...DEV_AUTH && { auth: AUTH },
      onUploadProgress: (progressEvent) => { getUploadProgress(progressEvent); }
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async deleteFileUploadFile(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async createFileUploadInspect(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/?action=inspect&asynchrone=false`), DOMAIN);
    const response = await axios.patch(url, {}, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createFileUploadResource(id, action, data, async = true) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileUploadResource(id, action, data, async = true) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },


  // FTP requests

  async createFTPFormat(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'ftp/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async setFTPScheduler(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.post(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async getFTPScheduler(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createFTPResource(id, action, data, async = true) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFTPFormat(id, format) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/`), DOMAIN);
    const response = await axios.patch(url, format, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFTPResource(resourceData, action, data, async = true) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${resourceData.id}/`), DOMAIN);
    const response = await axios.patch(
      url,
      { href: resourceData.selectedFtpFiles.map(el => el.value) },
      { ...DEV_AUTH && { auth: AUTH } }
    );
    const url2 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${resourceData.id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response2 = await axios.patch(url2, { kwargs: data.kwargs }, { ...DEV_AUTH && { auth: AUTH } });
    if (response2.status === 200) {
      return response.data;
    }
    return false;
  },


  // HREF requests

  async createHrefFormat(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'href/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  // async createHrefInspect(id) {
  //   const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/?action=inspect&asynchrone=false`), DOMAIN);
  //   const response = await axios.patch(url, {}, { ...DEV_AUTH && { auth: AUTH } });
  //   if (response.status === 200) {
  //     return response.data;
  //   }
  //   return false;
  // },

  async setHrefScheduler(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.post(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async updateHrefScheduler(resourceId, data, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceId}/scenario-execution-frequency/${scenarioId}/`), DOMAIN);
    const response = await axios.patch(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async removeHrefScheduler(resourceId, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceId}/scenario-execution-frequency/${scenarioId}/`), DOMAIN);
    const response = await axios.delete(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 204) {
      return true;
    }
    return false;
  },

  async createHrefResource(id, action, data, async = false) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateHrefFormat(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateHrefResource(resourceData, action, data, async = false) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceData.id}/`), DOMAIN);
    const response = await axios.patch(
      url,
      {
        href: resourceData.href,
        dataformat_id: resourceData.format.id
      },
      { ...DEV_AUTH && { auth: AUTH } }
    );
    const url2 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceData.id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response2 = await axios.patch(url2, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response2.status === 200) {
      return response.data;
    }
    return false;
  },

  // File Download requests
  async createFileDownloadFormat(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'file-download/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async setFileDownloadScheduler(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.post(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async updateFileDownloadScheduler(resourceId, data, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${resourceId}/scenario-execution-frequency/${scenarioId}/`), DOMAIN);
    const response = await axios.patch(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async removeFileDownloadScheduler(resourceId, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${resourceId}/scenario-execution-frequency/${scenarioId}/`), DOMAIN);
    const response = await axios.delete(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 204) {
      return true;
    }
    return false;
  },

  async createFileDownloadResource(id, action, data, async = false) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileDownloadFormat(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileDownloadResource(resourceData, action, data, async = false) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${resourceData.id}/`), DOMAIN);
    const response = await axios.patch(
      url,
      {
        href: resourceData.href,
        dataformat_id: resourceData.format.id
      },
      { ...DEV_AUTH && { auth: AUTH } }
    );
    const url2 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${resourceData.id}/?action=${action}&asynchrone=${async}`), DOMAIN);
    const response2 = await axios.patch(url2, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response2.status === 200) {
      return response.data;
    }
    return false;
  },

  // Annexes
  
  async createFileUploadResourceAnnexe(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/?action=create_resource&asynchrone=false`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileUploadAnnexe(datasetId, resourceId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${resourceId}/`), DOMAIN);
    const response = await axios.patch(url, {}, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      const url2 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=build_elastic_index&asynchrone=true`), DOMAIN);
      await axios.patch(url2, {}, { ...DEV_AUTH && { auth: AUTH } });
      return response.data;
    }
    return false;
  },

  async createFTPResourceAnnexe(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/?action=create_resource&asynchrone=false`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFTPAnnexe(datasetId, resourceId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${resourceId}/`), DOMAIN);
    const response = await axios.patch(url, {}, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      const url2 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=build_elastic_index&asynchrone=true`), DOMAIN);
      await axios.patch(url2, {}, { ...DEV_AUTH && { auth: AUTH } });
      return response.data;
    }
    return false;
  },

  async createHrefResourceAnnexe(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/?action=create_resource&asynchrone=false`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateHrefAnnexe(data, datasetId, resourceId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${data.id}/`), DOMAIN);
    const response =
      await axios.patch(url, { href: data.href, dataformat_id: data.format.id }, { ...DEV_AUTH && { auth: AUTH } });
    const url1 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${data.id}/?action=inspect&asynchrone=false`), DOMAIN);
    await axios.patch(url1, {}, { ...DEV_AUTH && { auth: AUTH } });
    const url2 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${resourceId}/`), DOMAIN);
    const response2 = await axios.patch(url2, {}, { ...DEV_AUTH && { auth: AUTH } });
    if (response2.status === 200) {
      const url3 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=build_elastic_index&asynchrone=true`), DOMAIN);
      await axios.patch(url3, {}, { ...DEV_AUTH && { auth: AUTH } });
      return response.data;
    }
    return false;
  },

  async createFileDownloadResourceAnnexe(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${id}/?action=create_resource&asynchrone=false`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateFileDownloadAnnexe(data, datasetId, resourceId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${data.id}/`), DOMAIN);
    const response =
      await axios.patch(url, { href: data.href, dataformat_id: data.format.id }, { ...DEV_AUTH && { auth: AUTH } });
    const url1 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-download/${data.id}/?action=inspect&asynchrone=false`), DOMAIN);
    await axios.patch(url1, {}, { ...DEV_AUTH && { auth: AUTH } });
    const url2 = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${resourceId}/`), DOMAIN);
    const response2 = await axios.patch(url2, {}, { ...DEV_AUTH && { auth: AUTH } });
    if (response2.status === 200) {
      const url3 = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/?action=build_elastic_index&asynchrone=true`), DOMAIN);
      await axios.patch(url3, {}, { ...DEV_AUTH && { auth: AUTH } });
      return response.data;
    }
    return false;
  },

  async appendAnnexeResourceToDataset(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'resource-dataset/'), DOMAIN);
    await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    return false;
  },

};

export default resourcesAPI;
