import resourcesAPI from '@/api/resourcesAPI.js';
import datastoresAPI from '@/api/datastoresAPI.js';
import geoserverAPI from '@/api/geoserverAPI';

import { ErrorService } from '@/services/error-service.js';
import miscAPI from '../../api/miscAPI';
// import { slugify } from '@/utils';

import modules from './resource';

/**************** STATE *******************/
const state = {
  mainResource: null,
  mainResourceData: null,
  mainResourceGeoData: null,
  mainResourceDatasetRelation: null,

  annexResources: [],
  annexResourcesDatasetRelation: [],
  annexResourcesData: [],

  lastCreatedResource: null,
  resourceDataFormats: [],
  resourceKinds: [],
  resourcesError: null,
  resourceInspectionData: null,
  currentResourceId: null,
  currentResourceScenarios: {
    create: [],
    update: [],
  },
  currentPublicationScenarios: [],
  currentResourcesFilters: {
    resource_type_id: [],
    dataset_id: []
  },

  ftpChoicesLoading: false,
  ftpChoices: [],
  schedulers: []
};

/**************** GETTERS *****************/
const getters = {
  mainResourceRoot: (state) => {
    if (state.mainResource && state.mainResource.rel && state.mainResource.rel.length) {
      return state.mainResource.rel[0];
    }
    return null;
  },
  mainResourceRels: (state) => {
    if (state.mainResource && state.mainResource.rel && state.mainResource.rel.length) {
      return state.mainResource.rel.slice(1);
    }
    return null;
  },
  mainResourceGeographicLayer: (state) => {
    if (state.mainResource && state.mainResource.rel && state.mainResource.rel.length) {
      return state.mainResource.rel.find(el => el['@app'] === 'onegeo_geospatial' && el['@class'] === 'geographiclayer');
    }
    return null;
  },
  currentResourcePublishScenario: (state) => (id) => {
    const found = state.currentPublicationScenarios.find(el => el.id === id);
    if (found && found.scenarios) {
      return found.scenarios.find(el => el.codename === 'publish-resourcedataset');
    }
    return null;
  },
  currentResourceUnpublishScenario: (state) => (id) => {
    const found = state.currentPublicationScenarios.find(el => el.id === id);
    if (found && found.scenarios) {
      return found.scenarios.find(el => el.codename === 'unpublish-resourcedataset');
    }
    return null;
  },
  publishResourceScenariosFound:(state, getters) => (id) => {
    return (getters.currentResourcePublishScenario(id) && getters.currentResourcePublishScenario(id).id) &&
      (getters.currentResourceUnpublishScenario(id) && getters.currentResourceUnpublishScenario(id).id);
  },
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_MAIN_RESOURCE: (state, payload) => {
    if (payload) {
      state.mainResource = payload;
    } else {
      state.mainResource = null;
    }
  },

  SET_MAIN_RESOURCE_DATA: (state, payload) => {
    if (payload) {
      state.mainResourceData = payload;
    } else {
      state.mainResourceData = null;
    }
  },
  SET_MAIN_RESOURCE_GEO_DATA: (state, payload) => {
    if (payload) {
      state.mainResourceGeoData = payload;
    } else {
      state.mainResourceGeoData = null;
    }
  },

  SET_MAIN_RESOURCE_DATASET_RELATION: (state, payload) => {
    if (payload) {
      state.mainResourceDatasetRelation = payload;
    } else {
      state.mainResourceDatasetRelation = null;
    }
  },

  SET_RESOURCE_PUBLICATION_SCENARIOS: (state, payload) => {
    if (payload) {
      const index = state.currentPublicationScenarios.findIndex(el => el.id === payload.id);
      if (index === -1) {
        state.currentPublicationScenarios.push(payload);
      } else {
        state.currentPublicationScenarios[index] = payload;
      }
    }
    // TODO: ajouter nom de la ressource dans le message d'erreur
    if (
      state.currentPublicationScenarios
        .some(el => !el.scenarios.find(sce => sce.codename === 'publish-resourcedataset'))
    ) {
      ErrorService.onError(null, 'Scénario de publication de la ressource manquant.');
    }
    if (
      state.currentPublicationScenarios
        .some(el => !el.scenarios.find(sce => sce.codename === 'unpublish-resourcedataset'))
    ) {
      ErrorService.onError(null, 'Scénario de dépublication de la ressource manquant.');
    }
  },

  SET_ANNEX_RESOURCE: (state, payload) => {
    if (payload) {
      const index = state.annexResources.findIndex(el => el.id === payload.id);
      if (index === -1) {
        state.annexResources.push(payload);
      }
    } else {
      state.annexResources.splice(0);
    }
  },

  SET_ANNEX_DATASET_RELATION: (state, payload) => {
    if (payload) {
      const index = state.annexResourcesDatasetRelation.findIndex(el => el.id === payload.id);
      if (index === -1) {
        state.annexResourcesDatasetRelation.push(payload);
      }
    } else {
      state.annexResourcesDatasetRelation.splice(0);
    }
  },

  SET_RESOURCE_DATA_FORMATS: (state, payload) => {
    if (payload) {
      state.resourceDataFormats = payload;
    }
  },

  SET_RESOURCE_KINDS: (state, payload) => {
    if (payload) {
      state.resourceKinds = payload;
    }
  },

  SET_FTP_CHOICES: (state, payload) => {
    if (payload) {
      state.ftpChoices = payload;
    } else {
      state.ftpChoices = [];
    }
  },
  SET_FTP_CHOICES_LOADING: (state, payload) => {
    state.ftpChoicesLoading = payload;
  },

  SET_CURRENT_RESOURCE_ID: (state, payload) => {
    state.currentResourceId = payload;
  },

  SET_CURRENT_RESOURCE_SCENARIOS: (state, payload) => {
    if (payload) {
      state.currentResourceScenarios = payload;
    }
  },
  RESET_CURRENT_RESOURCE_FILE_SCENARIOS: (state) => {
    state.currentResourceScenarios = {
      create: [],
      update: [],
    };
  },

  SET_RESOURCE_INSPECTION_DATA: (state, payload) => {
    if (payload && payload.data_info) {
      state.resourceInspectionData = payload.data_info;
    } else if (payload === null) {
      state.resourceInspectionData = payload;
    }
  },

  SET_RESOURCES_FILTERS: (state, payload) => {
    if (state.currentResourcesFilters[payload.filter].findIndex(el => el === payload.value) === -1) {
      if (payload.filter === 'dataset_id') {
        state.currentResourcesFilters[payload.filter] = [payload.value];
      } else {
        state.currentResourcesFilters[payload.filter].push(payload.value);
      }
    }
  },

  REMOVE_RESOURCES_FILTERS: (state, payload) => {
    const index = state.currentResourcesFilters[payload.filter].findIndex(el => el === payload.value);
    if (index !== -1) {
      state.currentResourcesFilters[payload.filter].splice(index, 1);
    }
  },

  SET_LAST_CREATED_RESOURCE: (state, payload) => {
    state.lastCreatedResource = payload;
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
    }
    state.resourcesError = error;
  },

  SET_SCHEDULERS: (state, payload) => {
    if (payload.results) {
      state.schedulers = [...payload.results];
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_RESOURCES_LIST: async ({ state, commit }) => {
    const filters = state.currentResourcesFilters;
    const resources = await resourcesAPI.getResourcesList(filters);
    commit('SET_RESOURCES_LIST', resources);
  },

  GET_MAIN_RESOURCE: async ({ getters, commit, dispatch }, { id, resourceToDatasetId }) => {
    const resource = await resourcesAPI.getResource(id);
    commit('SET_MAIN_RESOURCE', resource);
    if (getters.mainResourceRoot && getters.mainResourceRoot['@uri']) {
      const resourceData = await resourcesAPI.getResourceData(getters.mainResourceRoot['@uri']);
      commit('SET_MAIN_RESOURCE_DATA', resourceData);
    }
    if (resourceToDatasetId) {
      await dispatch('GET_RESOURCE_DATASET_RELATION', resourceToDatasetId);
    }
  },

  GET_RESOURCE_DATASET_RELATION: async ({ commit }, resourceToDatasetId) => {
    const publicationScenarios = await resourcesAPI.getResourceToDatasetScenarios(resourceToDatasetId);
    commit('SET_RESOURCE_PUBLICATION_SCENARIOS', {
      id: resourceToDatasetId,
      scenarios: publicationScenarios
    });
    const resourceToDataset = await resourcesAPI.getResourceDatasetRelation(resourceToDatasetId);
    commit('SET_MAIN_RESOURCE_DATASET_RELATION', resourceToDataset);
  },

  GET_ANNEX_RESOURCE: async ({ commit, dispatch }, { id, resourceToDatasetId }) => {
    const annex = await resourcesAPI.getResource(id);
    commit('SET_ANNEX_RESOURCE', annex);
    if (resourceToDatasetId) {
      await dispatch('GET_ANNEX_DATASET_RELATION', resourceToDatasetId);
    }
  },
  GET_ANNEX_DATASET_RELATION: async ({ commit }, resourceToDatasetId) => {
    const publicationScenarios = await resourcesAPI.getResourceToDatasetScenarios(resourceToDatasetId);
    commit('SET_RESOURCE_PUBLICATION_SCENARIOS', {
      id: resourceToDatasetId,
      scenarios: publicationScenarios
    });
    const resourceToDataset = await resourcesAPI.getResourceDatasetRelation(resourceToDatasetId);
    commit('SET_ANNEX_DATASET_RELATION', resourceToDataset);
  },

  PATCH_RESOURCE: async (context, { id, data, datasetId, withoutActions }) => {
    await resourcesAPI.patchResource(id, data, datasetId, withoutActions);
  },

  GET_RESOURCE_DATA_FORMATS: async ({ commit }) => {
    const formats = await resourcesAPI.getResourceDataFormats();
    commit('SET_RESOURCE_DATA_FORMATS', formats);
  },

  GET_RESOURCE_KINDS: async ({ commit }) => {
    const kinds = await resourcesAPI.getResourceKinds();
    commit('SET_RESOURCE_KINDS', kinds);
  },

  GET_FTP_CHOICES: async ({ commit }, usergroupId) => {
    commit('SET_FTP_CHOICES_LOADING', true);
    const response = await resourcesAPI.getFTPChoices(usergroupId);
    const ftpChoices = response.map(el => { return { ...el, checked: false }; });
    ftpChoices.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    commit('SET_FTP_CHOICES', ftpChoices);
    commit('SET_FTP_CHOICES_LOADING', false);
  },

  DELETE_RESOURCE_FILE: async ({ commit }, id) => {
    await resourcesAPI.deleteFileUploadFile(id)
      .then(() => {
        commit('SET_RESOURCE_INSPECTION_DATA', null);
        commit('SET_CURRENT_RESOURCE_ID', null);
        commit('SET_ERROR', null);
      })
      .catch((err) => {
        commit('SET_ERROR', err);
      });
  },

  START_CREATE_RESOURCE: async ({ commit }, { typeResource, data, datastoreType }) => {
    try {
      let scenarios;
      if (typeResource === 'file-upload') {
        commit('modal/OPEN_MODAL', {
          modal: 'progress',
          title: '',
          content: ''
        }, { root: true });
        const resp1 = await resourcesAPI.createFileUploadFormat(data);
        const resp2 = await resourcesAPI.createFileUploadFile(resp1.id, data.file);
        commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
        commit('SET_CURRENT_RESOURCE_ID', resp2.id);
        scenarios = resp2._scenarios;
      } else if (typeResource === 'ftp') {
        const resp1 = await resourcesAPI.createFTPFormat(data);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        scenarios = resp1._scenarios;
      } else if (typeResource === 'href') {
        const resp1 = await resourcesAPI.createHrefFormat(data);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        scenarios = resp1._scenarios;
      } else if (typeResource === 'file-download') {
        const resp1 = await resourcesAPI.createFileDownloadFormat(data);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        scenarios = resp1._scenarios;
      } else if (typeResource === 'datastore') {
        const resp1 = await datastoresAPI.createDatastore(datastoreType, data);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        scenarios = resp1._scenarios;
      } else if (typeResource === 'geoserver') {
        const resp1 = await geoserverAPI.createGeoserverSource(data);
        if (resp1) {
          commit('SET_CURRENT_RESOURCE_ID', resp1.id);
          scenarios = resp1._scenarios;
        } else {
          throw new Error('Geoserver resource already exist.');
        }
      }
      commit('SET_CURRENT_RESOURCE_SCENARIOS', scenarios);
    } catch(err) {
      commit('RESET_CURRENT_RESOURCE_FILE_SCENARIOS');
      commit('SET_ERROR', err);
      throw new Error(err);
    }
  },
  FINISH_CREATE_RESOURCE: async (
    { state, commit }, { typeResource, action, data, /*scheduler,*/ datastoreType, layerType, async }
  ) => {
    try {
      let createdResource;
      // let schedulerData;

      if (typeResource === 'file-upload') {
        createdResource = await resourcesAPI.createFileUploadResource(state.currentResourceId, action, data, async);
        commit('SET_LAST_CREATED_RESOURCE', createdResource);
        if (!createdResource.data_info.error) {
          ErrorService.onSuccess(createdResource, `La création de la ressource <b>${createdResource.file.filename}</b> a été lancée avec succès.`);
        }
      } else if (typeResource === 'ftp') {
        createdResource = await resourcesAPI.createFTPResource(state.currentResourceId, action, data);
        // if (scheduler) {
        //   const schedulerName = `Mise à jour ${scheduler.label} - ${createdResource.resource.codename}`;
        //   schedulerData = {
        //     codename: slugify(schedulerName),
        //     display_name: schedulerName,
        //     core_scenario_id: action,
        //     scheduler: {
        //       class_id: scheduler.class_id,
        //       id: scheduler.id
        //     },
        //     kwargs: {
        //       dataset_id: data.kwargs.dataset_id
        //     }
        //   };
        //   await resourcesAPI.setFTPScheduler(state.currentResourceId, schedulerData);
        // }
        if (createdResource) {
          commit('SET_LAST_CREATED_RESOURCE', createdResource);
          ErrorService.onSuccess(createdResource, `La création de la ressource <b>${createdResource.file.filename}</b> a été lancée avec succès.`);
        }
      } else if (typeResource === 'href') {
        createdResource = await resourcesAPI.createHrefResource(state.currentResourceId, action, data);
        // if (scheduler) {
        //   const schedulerName = `Mise à jour ${scheduler.label} - ${createdResource.resource.codename}`;
        //   schedulerData = {
        //     codename: slugify(schedulerName),
        //     display_name: schedulerName,
        //     core_scenario_id: action,
        //     scheduler: {
        //       class_id: scheduler.class_id,
        //       id: scheduler.id
        //     },
        //     kwargs: {
        //       dataset_id: data.kwargs.dataset_id
        //     }
        //   };
        //   await resourcesAPI.setHrefScheduler(state.currentResourceId, schedulerData);
        // }
        if (createdResource) {
          commit('SET_LAST_CREATED_RESOURCE', createdResource);
          ErrorService.onSuccess(createdResource, `La création de la ressource <b>${createdResource.file.filename}</b> a été lancée avec succès.`);
        }
      } else if (typeResource === 'file-download') {
        createdResource =
          await resourcesAPI.createFileDownloadResource(state.currentResourceId, action, data, true/*async*/);
        // if (scheduler) {
        //   const schedulerName = `Mise à jour ${scheduler.label} - file_download_${createdResource.id}`;
        //   schedulerData = {
        //     codename: slugify(schedulerName),
        //     display_name: schedulerName,
        //     core_scenario_id: action,
        //     scheduler: {
        //       class_id: scheduler.class_id,
        //       id: scheduler.id
        //     },
        //     kwargs: {
        //       dataset_id: data.kwargs.dataset_id
        //     }
        //   };
        //   await resourcesAPI.setFileDownloadScheduler(state.currentResourceId, schedulerData);
        // }
        if (createdResource) {
          commit('SET_LAST_CREATED_RESOURCE', createdResource);
          ErrorService.onSuccess(createdResource, `La création de la ressource <b>${createdResource.file.filename}</b> a été lancée avec succès.`);
        }
      } else if (typeResource === 'datastore') {
        createdResource =
          await datastoresAPI.createDatastoreResource(state.currentResourceId, datastoreType, action, data);
        if (createdResource) {
          commit('SET_LAST_CREATED_RESOURCE', createdResource);
          ErrorService.onSuccess(createdResource, `La création de la ressource <b>${createdResource.file.filename}</b> a été lancée avec succès.`);
        }
      } else if (typeResource === 'geoserver') {
        createdResource = await geoserverAPI.createGeoservResource(state.currentResourceId, layerType, action, data);
        if (createdResource) {
          commit('SET_LAST_CREATED_RESOURCE', createdResource);
          ErrorService.onSuccess(createdResource, `La création de la ressource <b>${createdResource.file.filename}</b> a été lancée avec succès.`);
        }
      }
      commit('RESET_CURRENT_RESOURCE_FILE_SCENARIOS');
    } catch (err) {
      commit('SET_ERROR', err);
    }
  },

  START_MODIFY_RESOURCE: async ({ commit }, { typeResource, data, datastoreType, async }) => {
    try {
      if (typeResource === 'file-upload') {
        commit('modal/OPEN_MODAL', {
          modal: 'progress',
          title: '',
          content: ''
        }, { root: true });
        const resp1 = await resourcesAPI.updateFileUploadFormat(
          data.location,
          { dataformat_id: data.format.id }
        );
        const resp2 = await resourcesAPI.updateFileUploadFile(data.location, data.file);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        commit('SET_CURRENT_RESOURCE_SCENARIOS', resp2._scenarios);
      } else if (typeResource === 'ftp') {
        const resp1 = await resourcesAPI.updateFTPFormat(data.id, { dataformat_id: data.format.id });
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        commit('SET_CURRENT_RESOURCE_SCENARIOS', resp1._scenarios);
      } else if (typeResource === 'href') {
        const resp1 = await resourcesAPI.updateHrefFormat(data.id, { dataformat_id: data.format.id });
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        commit('SET_CURRENT_RESOURCE_SCENARIOS', resp1._scenarios);
      } else if (typeResource === 'file-download') {
        const resp1 = await resourcesAPI.updateFileDownloadFormat(data.id, { dataformat_id: data.format.id });
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        commit('SET_CURRENT_RESOURCE_SCENARIOS', resp1._scenarios);
      } else if (typeResource === 'datastore') {
        await datastoresAPI.updateDatastore(data.id, datastoreType, data);
        const resp1 = await datastoresAPI.getDatastoreUpdateScenarios(datastoreType, data.id, async);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        commit('SET_CURRENT_RESOURCE_SCENARIOS', resp1._scenarios);
      }
    } catch(err) {
      commit('SET_ERROR', err);
      throw new Error(err);
    }
  },
  FINISH_MODIFY_RESOURCE: async (
    { state, commit }, { type, typeResource, action, data, resourceData, datasetId, resourceId, datastoreType, datastoreId, async }
  ) => {
    try {
      if (type === 'resource') {
        if (typeResource === 'file-upload') {
          const modifiedResource =
            await resourcesAPI.updateFileUploadResource(state.currentResourceId, action, data, async);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de la ressource <b>${modifiedResource.file.filename}</b> a été lancée avec succès.`);
          }
        } else if (typeResource === 'ftp') {
          const modifiedResource = await resourcesAPI.updateFTPResource(resourceData, action, data);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de la ressource <b>${modifiedResource.resource.codename}</b> a été lancée avec succès.`);
          }
        } else if (typeResource === 'href') {
          const modifiedResource = await resourcesAPI.updateHrefResource(resourceData, action, data, async);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de la ressource <b>${modifiedResource.resource.display_name ? modifiedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
          }
        } else if (typeResource === 'file-download') {
          const modifiedResource = await resourcesAPI.updateFileDownloadResource(resourceData, action, data, async);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de la ressource <b>${modifiedResource.resource.display_name ? modifiedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
          }
        } else if (typeResource === 'datastore') {
          const modifiedResource = await datastoresAPI.updateDatastoreResource(datastoreId, datastoreType, action, data);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de la ressource <b>${modifiedResource.resource.display_name ? modifiedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
          }
        }
      } else {
        if (typeResource === 'file-upload') {
          const modifiedResource = await resourcesAPI.updateFileUploadAnnexe(datasetId, resourceId);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de l'annexe <b>${modifiedResource.codename}</b> a été lancée avec succès.`);
          }
        } else if (typeResource === 'ftp') {
          resourcesAPI.updateFTPFormat(resourceData.id, { dataformat_id: resourceData.format.id });
          const modifiedResource = await resourcesAPI.updateFTPAnnexe(datasetId, resourceId);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de l'annexe <b>${modifiedResource.codename}</b> a été lancée avec succès.`);
          }
        } else if (typeResource === 'href') {
          const modifiedResource = await resourcesAPI.updateHrefAnnexe(resourceData, datasetId, resourceId, async);
          if (modifiedResource) {
            commit('SET_LAST_CREATED_RESOURCE', modifiedResource);
            ErrorService.onSuccess(modifiedResource, `La mise à jour de la ressource <b>${modifiedResource.resource.display_name ? modifiedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
          }
        }
      }
    } catch(err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  START_UPDATE_RESOURCE: async ({ commit }, { typeResource, data, datastoreType, layerType, async }) => {
    try {
      if (typeResource === 'datastore') {
        const resp1 = await datastoresAPI.getDatastoreUpdateScenarios(datastoreType, data.id, async);
        commit('SET_CURRENT_RESOURCE_ID', resp1.id);
        commit('SET_CURRENT_RESOURCE_SCENARIOS', resp1._scenarios);
      } else if (typeResource === 'geoserver') {
        await geoserverAPI.getGeoserverUpdateScenarios(layerType, data.id);
      }
    } catch(err) {
      commit('SET_ERROR', err);
      throw new Error(err);
    }
  },

  FINISH_UPDATE_RESOURCE: async ({ commit }, { type, typeResource, action, datastoreType, datastoreId, kwargs }) => {
    try {
      if (type === 'resource') {
        if (typeResource === 'datastore') {
          const updatedResource =
            await datastoresAPI.updateDatastoreResource(datastoreId, datastoreType, action, { kwargs: kwargs });
          if (updatedResource) {
            commit('SET_LAST_CREATED_RESOURCE', updatedResource);
            ErrorService.onSuccess(updatedResource, `La mise à jour de la ressource <b>${updatedResource.resource.display_name ? updatedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
          }
        }
      }
    } catch(err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  // UPDATE_HREF_RESOURCE: async ({ commit }, { data, async }) => {
  //   try {
  //     const updatedResource = await resourcesAPI.updateHrefResource(data, async);
  //     if (updatedResource) {
  //       commit('SET_LAST_CREATED_RESOURCE', updatedResource);
  //       ErrorService.onSuccess(updatedResource, `La mise à jour de la ressource <b>${updatedResource.resource.display_name ? updatedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     commit('SET_ERROR', err);
  //   }
  // },
  // UPDATE_HREF_ANNEXE: async ({ commit }, { data, datasetId, resourceId, async }) => {
  //   try {
  //     const updatedResource = await resourcesAPI.updateHrefAnnexe(data, datasetId, resourceId, async);
  //     if (updatedResource) {
  //       commit('SET_LAST_CREATED_RESOURCE', updatedResource);
  //       ErrorService.onSuccess(updatedResource, `La mise à jour de la ressource <b>${updatedResource.resource.display_name ? updatedResource.resource.display_name : ''}</b> a été lancée avec succès.`);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     commit('SET_ERROR', err);
  //   }
  // },

  CREATE_FILE_UPLOAD_ANNEXE: async ({ commit, dispatch }, data) => {
    try {
      commit('modal/OPEN_MODAL', {
        modal: 'progress',
        title: '',
        content: ''
      }, { root: true });
      const resp1 = await resourcesAPI.createFileUploadFormat({
        dataformat_id: data.dataformat_id
      });
      const resp2 = await resourcesAPI.createFileUploadFile(resp1.id, data.file);
      const kwargs = {
        kwargs: {
          resource__display_name: data.displayName,
          resource__description: data.description,
          resource__kind_id: data.kind ? data.kind.id : null,
          resource__last_update_date: data.lastUpdateDate ? data.lastUpdateDate : null,
          usergroup_id: data.usergroup_id
        }
      };
      const resp3 = await resourcesAPI.createFileUploadResourceAnnexe(resp2.id, kwargs);
      commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
      ErrorService.onSuccess(resp3, 'La ressource annexe a été créé avec succès.');
      dispatch('APPEND_ANNEXE_TO_DATASET', {
        resourceId: resp3.resource.id,
        dataset: data.dataset
      });
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  CREATE_FTP_ANNEXE: async ({ commit, dispatch }, data) => {
    try {
      const resp1 = await resourcesAPI.createFTPFormat({
        dataformat_id: data.dataformat_id,
        href: data.href
      });
      const kwargs = {
        kwargs: {
          resource__display_name: data.displayName,
          resource__description: data.description,
          resource__kind_id: data.kind ? data.kind.id : null,
          resource__last_update_date: data.lastUpdateDate ? data.lastUpdateDate : null,
          usergroup_id: data.usergroup_id
        }
      };
      const resp2 = await resourcesAPI.createFTPResourceAnnexe(resp1.id, kwargs);
      ErrorService.onSuccess(resp2, 'La ressource annexe a été créé avec succès.');
      dispatch('APPEND_ANNEXE_TO_DATASET', {
        resourceId: resp2.resource.id,
        dataset: data.dataset
      });
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  CREATE_HREF_ANNEXE: async ({ commit, dispatch }, data) => {
    try {
      const resp1 = await resourcesAPI.createHrefFormat(data);
      const kwargs = {
        kwargs: {
          resource__display_name: data.displayName,
          resource__description: data.description,
          resource__kind_id: data.kind ? data.kind.id : null,
          resource__last_update_date: data.lastUpdateDate ? data.lastUpdateDate : null,
          usergroup_id: data.usergroup_id
        }
      };
      const resp2 = await resourcesAPI.createHrefResourceAnnexe(resp1.id, kwargs);
      ErrorService.onSuccess(resp2, 'La ressource annexe a été créé avec succès.');
      dispatch('APPEND_ANNEXE_TO_DATASET', {
        resourceId: resp2.resource.id,
        dataset: data.dataset
      });
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  CREATE_FILE_DOWNLOAD_ANNEXE: async ({ commit, dispatch }, data) => {
    try {
      const resp1 = await resourcesAPI.createFileDownloadFormat(data);
      const kwargs = {
        kwargs: {
          resource__display_name: data.displayName,
          resource__description: data.description,
          resource__kind_id: data.kind ? data.kind.id : null,
          usergroup_id: data.usergroup_id
        }
      };
      const resp2 = await resourcesAPI.createFileDownloadResourceAnnexe(resp1.id, kwargs);
      ErrorService.onSuccess(resp2, 'La ressource annexe a été créé avec succès.');
      dispatch('APPEND_ANNEXE_TO_DATASET', {
        resourceId: resp2.resource.id,
        dataset: data.dataset
      });
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  CREATE_DATASTORE_ANNEXE: async ({ commit, dispatch }, { datastoreType, data }) => {
    try {
      await datastoresAPI.createDatastore(datastoreType, data);
      const kwargs = {
        kwargs: {
          resource__display_name: data.displayName,
          resource__description: data.description,
          resource__kind_id: data.kind ? data.kind.id : null,
          resource__last_update_date: data.lastUpdateDate ? data.lastUpdateDate : null,
          usergroup_id: data.usergroup_id
        }
      };
      const resp2 = await datastoresAPI.createDatastoreAnnexe(state.currentResourceId, kwargs);
      ErrorService.onSuccess(resp2, 'La ressource annexe a été créé avec succès.');
      dispatch('APPEND_ANNEXE_TO_DATASET', {
        resourceId: resp2.resource.id,
        dataset: data.id
      });
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  APPEND_ANNEXE_TO_DATASET: ({ commit }, data) => {
    resourcesAPI.appendAnnexeResourceToDataset(
      {
        type: 2,
        resource_id: data.resourceId,
        dataset_id: data.dataset
      }
    )
      .catch((err) => {
        console.error(err);
        commit('SET_ERROR', err);
      });
  },

  DELETE_RESOURCE_FROM_DATASET: async ({ commit }, { resourceToDatasetId, resource }) => {
    try {
      await resourcesAPI.deleteResourceToDataset(resourceToDatasetId);
      // await resourcesAPI.deleteResource(resource.id);
      commit('SET_ERROR', null);
      ErrorService.onSuccess(true, `La ressource <b>${resource.display_name}</b> a été supprimée avec succès.`);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  DELETE_RESOURCE: async ({ commit }, resource) => {
    try {
      await resourcesAPI.deleteResource(resource.id);
      commit('SET_ERROR', null);
      ErrorService.onSuccess(true, `La ressource <b>${resource.display_name}</b> a été supprimée avec succès.`);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  GET_SCHEDULERS: async ({ commit }) => {
    const schedulers = await miscAPI.getSchedulers();
    commit('SET_SCHEDULERS', schedulers);
  }
};

export default {
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
};
