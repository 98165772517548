import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const DATASET_API_PATH = process.env.VUE_APP_DATASET_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const datasetsAPI = {

  async getDatasetsCount() {
    const url = new URL(
      path.join(`${i18n.locale}${DATASET_API_PATH}`, 'datasets-count/'),
      DOMAIN
    );
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsList(
    page = 1,
    usergroup,
    kinds,
    filters,
    controller
  ) {
    const url = new URL(
      path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/?page=${page}`),
      DOMAIN
    );
    if (usergroup) {
      url.hef = url.href.concat('', `&usergroup_id=${usergroup}`);
    }
    if (kinds) {
      url.href = url.href.concat('', `&kind=${kinds.join(',')}`);
    }
    for (const filter in filters) {
      url.href = url.href.concat('', `&${filter}=${filters[filter]}`);
    }
    const response = await axios.get(
      url,
      { 
        signal: controller.signal,
        ...DEV_AUTH && { auth: AUTH }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async orderDatasetsList(
    direction,
    field,
    page = 1,
    usergroup,
    kinds,
    filters,
    controller
  ) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/?ordering=${direction}${field}&page=${page}`), DOMAIN);
    if (usergroup) {
      url.href = url.href.concat('', `&usergroup_id=${usergroup}`);
    }
    if (kinds) {
      url.href = url.href.concat('', `&kind=${kinds.join(',')}`);
    }
    for (const filter in filters) {
      url.href = url.href.concat('', `&${filter}=${filters[filter]}`);
    }
    const response = await axios.get(
      url,
      { 
        signal: controller.signal,
        ...DEV_AUTH && { auth: AUTH }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDataset(id) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetPermissions(id, filters, page = 1) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `usergroup-permissions/?dataset_id=${id}&page=${page}`), DOMAIN);
    if (Object.values(filters).some(el => el && el.length > 0)) {
      for (const filter in filters) {
        if (filters[filter] && filters[filter].length > 0) {
          url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
        }
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async orderDatasetPermissions(direction, field, id, filters, page = 1) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `usergroup-permissions/?ordering=${direction}${field}&dataset_id=${id}&page=${page}`), DOMAIN);
    if (Object.values(filters).some(el => el && el.length > 0)) {
      for (const filter in filters) {
        if (filters[filter] && filters[filter].length > 0) {
          url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
        }
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async setDatasetPermissions(level, data) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `usergroup-permission-actions/?action=set_permission_level_${level}`), DOMAIN);
    const response = await axios.put(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createDataset(data) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'datasets/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async updateDataset(id, data, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      if (scenarioId) await this.buildDatasetIndex(id, scenarioId);
      return response.data;
    }
    return false;
  },

  async buildDatasetIndex(id, scenarioId, asynchrone = true) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/_scenarios/${scenarioId}/run/?asynchrone=${asynchrone}`), DOMAIN);
    const response = await axios.post(url, null, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  async patchDataset(id, data) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async setDatasetThumbnail(id, data) {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/thumbnail/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async removeDatasetThumbnail(id) {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/thumbnail/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async deleteDataset(id) {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return true;
    }
    return false;
  },

  async getDatasetsCategories() {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'categories/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsGranularities() {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'granularities/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsLicences() {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'licenses/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsTopics() {
    const url =  new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'topics/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsKinds() {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'kinds/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsRestrictions() {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'restrictions/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsUpdateFrequencies() {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'update-frequencies/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsRecordTypes() {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'record-types/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetsRoles() {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'contact-roles/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getCurrentDatasetRoles(id) {
    try {
      const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${id}/contact-roles/`), DOMAIN);
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (err) {
      return false;
    }
  },

  async createDatasetRole(datasetId, data) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/contact-roles/`), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async updateDatasetRole(datasetId, data, roleId, force = false) {
    const url = new URL(path.join(
      `${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/contact-roles/${roleId}/${force ? '?force=true' : ''}`
    ) ,DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async removeDatasetRole(datasetId, roleId) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `datasets/${datasetId}/contact-roles/${roleId}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async getContact(id) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `contacts/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getContacts(usergroupId, page) {
    let url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'contacts/'), DOMAIN);
    if (usergroupId) {
      url = url + `?usergroup_id=${usergroupId}`;
      if (page) {
        url = url + `&page=${page}`;
      }
    } else {
      if (page) {
        url = url + `?page=${page}`;
      }
    }
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createContact(data) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, 'contacts/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async updateContact(id, data) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `contacts/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async removeContact(id) {
    const url = new URL(path.join(`${i18n.locale}${DATASET_API_PATH}`, `contacts/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

};

export default datasetsAPI;
