<template>
  <div id="app-header">
    <b-navbar sticky>
      <b-navbar-brand>
        <img
          role="link"
          :src="logoPath"
          alt="Logo"
          @click="goToHomePage"
        >
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          :active="$route.path.includes('data')"
          :disabled="$route.name === 'Data' || !accessData"
        >
          <router-link
            v-slot="{ navigate }"
            :to="{
              name: 'Data',
              params: {
                locale: $i18n.locale
              }
            }"
            custom
          >
            <span
              role="link"
              @click="navigate"
            >
              {{ $t('header.data') }}
            </span>
          </router-link>
        </b-nav-item>

        <b-nav-item
          :active="$route.path.includes('accounts')"
          :disabled="$route.name === 'Accounts' || !accessAccount"
        >
          <router-link
            v-slot="{ navigate }"
            :to="{
              name: 'Accounts',
              params: {
                locale: $i18n.locale
              }
            }"
            custom
          >
            <span
              role="link"
              @click="navigate"
            >
              {{ $t('header.accounts') }}
            </span>
          </router-link>
        </b-nav-item>

        <b-nav-item-dropdown
          id="services-nav-dropdown"
          :text="$t('header.services')"
          toggle-class="nav-link-custom"
          left
        >
          <b-dropdown-item
            v-if="
              $config.services.portal &&
                (
                  $config.services.portal.available ||
                  (userData.is_superuser && $config.services.portal.superuser)
                )
            "
            @click="goToPortail"
          >
            <span role="link">
              {{ $t('header.portal') }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              $config.services.maps &&
                (
                  $config.services.maps.available ||
                  (userData.is_superuser && $config.services.maps.superuser)
                )
            "
            @click="goToMaps"
          >
            <span role="link">
              {{ $t('header.map') }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              $config.services.stats &&
                (
                  $config.services.stats.available ||
                  (userData.is_superuser && $config.services.stats.superuser)
                )
            "
            @click="goToStats"
          >
            <span role="link">
              {{ $t('header.stats') }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              $config.services.geoserver &&
                (
                  $config.services.geoserver.available ||
                  (userData.is_superuser && $config.services.geoserver.superuser)
                )
            "
            @click="goToGeoserver"
          >
            <span role="link">GEOSERVER</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              $config.services.geonetwork &&
                (
                  $config.services.geonetwork.available ||
                  (userData.is_superuser && $config.services.geonetwork.superuser)
                )
            "
            @click="goToGeonetwork"
          >
            <span role="link">GEONETWORK</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              $config.services.majic &&
                (
                  $config.services.majic.available ||
                  (userData.is_superuser && $config.services.majic.superuser)
                )
            "
            @click="goToMajic"
          >
            <span role="link">ESPACE MAJIC</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          :id="popoverId"
          :class="isUserAuthenticated ? 'connected' : 'disconnected'"
        >
          <div
            v-if="isUserAuthenticated"
            class="user-name"
          >
            {{ `${userData.first_name ? userData.first_name : ''} ${userData.last_name ? userData.last_name : ''}` }}
          </div>
          <img
            src="@/assets/icons/account_profile_user.svg"
            alt="Icon account profile user"
          >
        </b-nav-item>

        <LocaleChanger />
      </b-navbar-nav>
    </b-navbar>
    <b-popover
      :target="popoverId"
      triggers="click blur"
      placement="bottom"
    >
      <template #title>
        <div style="text-align: center;">
          {{
            isUserAuthenticated ?
              `${userData.first_name ? userData.first_name : ''} ${userData.last_name ? userData.last_name : ''}` :
              'Déconnecté'
          }}
        </div>
      </template>
      <div
        v-if="isUserAuthenticated"
        class="popover-item"
      >
        <b-link @click="goToProfile">
          {{ $t('header.userDetails') }}
        </b-link>
      </div>
      <div
        v-else
        class="popover-item"
      >
        {{ $t('header.signInMessage') }}
      </div>
      <div
        v-if="!isUserAuthenticated"
        class="popover-item login-button"
        @click="goToLogin"
      >
        {{ $t('header.signIn') }}
        <b-icon-box-arrow-in-right scale="1.5" />
      </div>
      <div
        v-else
        class=" popover-item login-button"
        @click="signOut"
      >
        {{ $t('header.signOut') }}
        <b-icon-box-arrow-left scale="1.5" />
      </div>
    </b-popover>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LocaleChanger from '@/components/LocaleChanger';

export default {
  name: 'Header',

  components: {
    LocaleChanger
  },

  data() {
    return {
      popoverId: 'popover-user-',
      popoverIdCount: 0,
    };
  },

  computed: {
    ...mapState('user-login', [
      'isUserAuthenticated',
      'userData'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    },

    accessAccount() {
      return this.userData.is_superuser || (this.userData.usergroup_roles && this.userData.usergroup_roles.some(el => el.role === 2));
    },

    accessData() {
      return this.userData.is_superuser || (this.userData.usergroup_roles && this.userData.usergroup_roles.some(el => el.role >= 1));
    }
  },

  watch: {
    isUserAuthenticated() {
      this.popoverId = this.popoverId.concat(this.popoverIdCount.toString());
      this.popoverIdCount += 1;
    }
  },

  methods: {
    goToHomePage() {
      window.location.href = `${process.env.VUE_APP_HOME_ROUTE}`;
    },
    goToPortail() {
      window.open(`${process.env.VUE_APP_PORTAIL_ROUTE}recherche`, '_blank');
    },
    goToMaps() {
      window.open(`${process.env.VUE_APP_MAPS_ROUTE}`, '_blank');
    },
    goToStats() {
      window.open(`${process.env.VUE_APP_STATS_ROUTE}`, '_blank');
    },
    goToGeoserver() {
      window.open(`${process.env.VUE_APP_GEOSERVER_ROUTE}`, '_blank');
    },
    goToGeonetwork() {
      window.open(`${process.env.VUE_APP_GEONETWORK_ROUTE}`, '_blank');
    },
    goToMajic() {
      window.open(`${process.env.VUE_APP_MAJIC_ROUTE}`, '_blank');
    },
    goToProfile() {
      window.location.href = `${process.env.VUE_APP_LOGIN_PROFILE_ROUTE}?next=${window.location.pathname}${window.location.hash}`;
    },
    goToLogin() {
      window.location.href = `${process.env.VUE_APP_LOGIN_ROUTE}?next=${window.location.pathname}${window.location.hash}`;
    },
    async signOut() {
      window.location.href = `${process.env.VUE_APP_LOGIN_ROUTE}signout`;
    }
  }
};
</script>

<style lang="less" scoped>

#app-header {
  height: @headerHeight;
  background-color: @blue;
  padding: 0 1rem;

  .navbar {
    padding: 0;

    .navbar-brand {
      cursor: pointer;
      padding: 0;
      img{
        height: calc(@headerHeight - 10px);
      }
    }
    .navbar-nav {
      align-items: center;
      height: @headerHeight;
      .nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 1.5em;
        .nav-link {
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          vertical-align: bottom !important;
          color: rgba(255, 255, 255, 0.75);
          img {
            width: 1em;
            height: 1em;
            margin: 0;
          }
        }
        .nav-link.disabled {
          color: rgb(255, 255, 255, 0.5)
        }
        .nav-link.active {
          color: rgb(255, 255, 255);
          font-weight: bold;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          border-bottom: solid 3px @blue;
          text-shadow: 0 0 1px #ffffff;
        }
      }
      .nav-item:not([id^=popover-user]) {
        .nav-link:hover {
          color: rgb(255, 255, 255);
          .btn {
            opacity: 1;
          }
        }
      }
      [id^=popover-user] {
        margin-left: 1em;
        .nav-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          .b-icon {
            margin: 0 15px 0 10px;
            font-size: 1.5em;
          }
          .b-icon:hover {
            filter: invert(33%) sepia(91%) saturate(819%) hue-rotate(174deg) brightness(101%) contrast(92%);
          }
        }
      }
    }
  }
}
.disconnected {
  opacity: 1;
}
.connected {
  display: contents;
  img {
    filter: brightness(0) saturate(100%) invert(54%) sepia(12%) saturate(5215%) hue-rotate(67deg) brightness(96%) contrast(106%);
    opacity: 1;
  }
  .user-name {
    font-size: 0.8em;
    color: #ffffff;
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 1140px) {
  #app-header {
    .navbar {
      .navbar-brand {
        display: none;
      }
    }
  }
}

.popover-body {
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  .popover-item {
    width: 100%;
    padding: 1em;
    font-size: 0.9em;
    .login-button {
      margin: 0.5em auto;
    }
  }
  .login-button {
    cursor: pointer;
    padding: 0.5em;
    background-color: @blue;
    color: white;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    .b-icon {
      margin-left: 0.5em;
    }
  }
}
</style>
