import resourcesAPI from '@/api/resourcesAPI.js';

/**************** STATE *******************/
const state = {
  ftpChoicesLoading: false,
  ftpChoices: [],
};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {
  SET_FTP_CHOICES_LOADING: (state, payload) => {
    state.ftpChoicesLoading = payload;
  },
  SET_FTP_CHOICES: (state, payload) => {
    if (payload) {
      state.ftpChoices = payload;
    } else {
      state.ftpChoices = [];
    }
  },
};

/**************** ACTIONS *******************/
const actions = {
  GET_FTP_CHOICES: async ({ commit }, usergroupId) => {
    commit('SET_FTP_CHOICES_LOADING', true);
    const response = await resourcesAPI.getFTPChoices(usergroupId);
    const ftpChoices = response.map(el => { return { ...el, checked: false }; });
    ftpChoices.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    commit('SET_FTP_CHOICES', ftpChoices);
    commit('SET_FTP_CHOICES_LOADING', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};