var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app-header"}},[_c('b-navbar',{attrs:{"sticky":""}},[_c('b-navbar-brand',[_c('img',{attrs:{"role":"link","src":_vm.logoPath,"alt":"Logo"},on:{"click":_vm.goToHomePage}})]),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"active":_vm.$route.path.includes('data'),"disabled":_vm.$route.name === 'Data' || !_vm.accessData}},[_c('router-link',{attrs:{"to":{
            name: 'Data',
            params: {
              locale: _vm.$i18n.locale
            }
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('header.data'))+" ")])]}}])})],1),_c('b-nav-item',{attrs:{"active":_vm.$route.path.includes('accounts'),"disabled":_vm.$route.name === 'Accounts' || !_vm.accessAccount}},[_c('router-link',{attrs:{"to":{
            name: 'Accounts',
            params: {
              locale: _vm.$i18n.locale
            }
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('header.accounts'))+" ")])]}}])})],1),_c('b-nav-item-dropdown',{attrs:{"id":"services-nav-dropdown","text":_vm.$t('header.services'),"toggle-class":"nav-link-custom","left":""}},[(
            _vm.$config.services.portal &&
              (
                _vm.$config.services.portal.available ||
                (_vm.userData.is_superuser && _vm.$config.services.portal.superuser)
              )
          )?_c('b-dropdown-item',{on:{"click":_vm.goToPortail}},[_c('span',{attrs:{"role":"link"}},[_vm._v(" "+_vm._s(_vm.$t('header.portal'))+" ")])]):_vm._e(),(
            _vm.$config.services.maps &&
              (
                _vm.$config.services.maps.available ||
                (_vm.userData.is_superuser && _vm.$config.services.maps.superuser)
              )
          )?_c('b-dropdown-item',{on:{"click":_vm.goToMaps}},[_c('span',{attrs:{"role":"link"}},[_vm._v(" "+_vm._s(_vm.$t('header.map'))+" ")])]):_vm._e(),(
            _vm.$config.services.stats &&
              (
                _vm.$config.services.stats.available ||
                (_vm.userData.is_superuser && _vm.$config.services.stats.superuser)
              )
          )?_c('b-dropdown-item',{on:{"click":_vm.goToStats}},[_c('span',{attrs:{"role":"link"}},[_vm._v(" "+_vm._s(_vm.$t('header.stats'))+" ")])]):_vm._e(),(
            _vm.$config.services.geoserver &&
              (
                _vm.$config.services.geoserver.available ||
                (_vm.userData.is_superuser && _vm.$config.services.geoserver.superuser)
              )
          )?_c('b-dropdown-item',{on:{"click":_vm.goToGeoserver}},[_c('span',{attrs:{"role":"link"}},[_vm._v("GEOSERVER")])]):_vm._e(),(
            _vm.$config.services.geonetwork &&
              (
                _vm.$config.services.geonetwork.available ||
                (_vm.userData.is_superuser && _vm.$config.services.geonetwork.superuser)
              )
          )?_c('b-dropdown-item',{on:{"click":_vm.goToGeonetwork}},[_c('span',{attrs:{"role":"link"}},[_vm._v("GEONETWORK")])]):_vm._e(),(
            _vm.$config.services.majic &&
              (
                _vm.$config.services.majic.available ||
                (_vm.userData.is_superuser && _vm.$config.services.majic.superuser)
              )
          )?_c('b-dropdown-item',{on:{"click":_vm.goToMajic}},[_c('span',{attrs:{"role":"link"}},[_vm._v("ESPACE MAJIC")])]):_vm._e()],1),_c('b-nav-item',{class:_vm.isUserAuthenticated ? 'connected' : 'disconnected',attrs:{"id":_vm.popoverId}},[(_vm.isUserAuthenticated)?_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(((_vm.userData.first_name ? _vm.userData.first_name : '') + " " + (_vm.userData.last_name ? _vm.userData.last_name : '')))+" ")]):_vm._e(),_c('img',{attrs:{"src":require("@/assets/icons/account_profile_user.svg"),"alt":"Icon account profile user"}})]),_c('LocaleChanger')],1)],1),_c('b-popover',{attrs:{"target":_vm.popoverId,"triggers":"click blur","placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.isUserAuthenticated ? ((_vm.userData.first_name ? _vm.userData.first_name : '') + " " + (_vm.userData.last_name ? _vm.userData.last_name : '')) : 'Déconnecté')+" ")])]},proxy:true}])},[(_vm.isUserAuthenticated)?_c('div',{staticClass:"popover-item"},[_c('b-link',{on:{"click":_vm.goToProfile}},[_vm._v(" "+_vm._s(_vm.$t('header.userDetails'))+" ")])],1):_c('div',{staticClass:"popover-item"},[_vm._v(" "+_vm._s(_vm.$t('header.signInMessage'))+" ")]),(!_vm.isUserAuthenticated)?_c('div',{staticClass:"popover-item login-button",on:{"click":_vm.goToLogin}},[_vm._v(" "+_vm._s(_vm.$t('header.signIn'))+" "),_c('b-icon-box-arrow-in-right',{attrs:{"scale":"1.5"}})],1):_c('div',{staticClass:" popover-item login-button",on:{"click":_vm.signOut}},[_vm._v(" "+_vm._s(_vm.$t('header.signOut'))+" "),_c('b-icon-box-arrow-left',{attrs:{"scale":"1.5"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }