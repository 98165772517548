import usersAPI from '@/api/usersAPI.js';
import usergroupsAPI from '@/api/usergroupsAPI.js';
import { ErrorService } from '@/services/error-service.js';
import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};
const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;

/**************** STATE *******************/
const state = {
  currentUser: {},
  currentUserRoles: [],
  lastCreatedUser: null,
  usersCount: 0,
  usersList: [],
  usersError: null,
  currentUsersOrdering: {
    direction: null,
    field: null
  },
  currentUsersFilters: {
    status__in: [2]
  },
  isUsersListSearched: false,
  searchUsersFilter: null,
  userStatuses: []
};

/**************** GETTERS *****************/
const getters = {
  tablePage: (state, getters, rootState) => {
    try {
      return rootState.table.currentTables.find(t => t.name === 'usersListTable').currentPage;
    } catch {
      return 1;
    }
  }
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_USERS_LIST: (state, payload) => {
    if (payload && payload.results) {
      state.usersList = payload.results;
    }
    if (payload && payload.count !== null) {
      state.usersCount = payload.count;
    }
  },

  SET_CURRENT_USER: (state, payload) => {
    state.currentUser = payload;
  },
  SET_CURRENT_USER_ROLES: (state, payload) => {
    state.currentUserRoles = payload;
  },

  SET_LAST_CREATED_USER: (state, payload) => {
    state.lastCreatedUser = payload;
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      if (error.reponse && error.response.data) {
        state.usersError = error.response.data.detail ? error.response.data.detail : error.response.data;
      } else {
        state.usersError = error;
      }
    } else {
      state.usersError = error;
    }
  },

  SET_USERS_ORDERING: (state, payload) => {
    state.currentUsersOrdering = payload;
  },

  SET_USERS_FILTERS: (state, payload) => {
    if (payload && (payload.value !== null && payload.value !== undefined)) {
      state.currentUsersFilters[payload.filter].push(payload.value);
    }
  },

  REMOVE_USERS_FILTERS: (state, payload) => {
    if (payload && (payload.value !== null && payload.value !== undefined)) {
      state.currentUsersFilters[payload.filter].splice(payload.index, 1);
    }
  },

  SET_IS_USERSLIST_SEARCHED: (state, payload) => {
    state.isUsersListSearched = payload.isSearched;
    state.searchUsersFilter = payload.text;
  },

  SET_USER_STATUSES: (state, payload) => {
    if (payload && payload.length) {
      state.userStatuses = [...payload];
    } else {
      state.userStatuses = [];
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
  PRESET_USERS_FILTERS: ({ state, commit }, filter) => {
    if (state.currentUsersFilters[filter.filter].findIndex(el => el === filter.value) === -1) {
      commit('table/SET_CURRENT_PAGE', { name: 'usersListTable', page: 1 }, { root: true });
      commit('SET_USERS_FILTERS', filter);
    }
  },

  PREREMOVE_USERS_FILTERS: ({ state, commit }, filter) => {
    const index = state.currentUsersFilters[filter.filter].findIndex(el => el === filter.value);
    if (index !== -1) {
      commit('table/SET_CURRENT_PAGE', { name: 'usersListTable', page: 1 }, { root: true });
      commit('REMOVE_USERS_FILTERS', { index: index, ...filter });
    }
  },

  GET_USER_STATUSES: async ({ commit }) => {
    try {
      const userStatuses = await usergroupsAPI.getUserStatuses();
      commit('SET_USER_STATUSES', userStatuses);
    } catch {
      return;
    }
  },

  GET_USERS_LIST: async ({ state, getters, commit, dispatch }, { direction, field, page }) => {
    try {
      if (!page) {
        page = getters.tablePage;
      }
      // Save sorting params
      commit('SET_USERS_ORDERING', { direction, field });

      if (state.isUsersListSearched) {
        dispatch('SEARCH_USERS_LIST', { text: state.searchUsersFilter });
      } else if (field) {
        await usersAPI.orderUsersList(direction, field, state.currentUsersFilters, page)
          .then((users) => {
            if (users) {
              commit('SET_ERROR', null);
              commit('SET_USERS_LIST', users);
              commit('SET_IS_USERSLIST_SEARCHED', {
                isSearched: false,
                text: null
              });
            }
          })
          .catch((error) => {
            commit('SET_ERROR', error);
          });
      } else {
        const filters = state.currentUsersFilters;
        await usersAPI.getUsersList(filters, page)
          .then((users) => {
            if (users) {
              commit('SET_ERROR', null);
              commit('SET_USERS_LIST', users);
              commit('SET_IS_USERSLIST_SEARCHED', {
                isSearched: false,
                text: null
              });
            }
          })
          .catch((error) => {
            commit('SET_ERROR', error);
          });
      }
    } catch (err) {
      console.error(err);
    }
  },

  GET_USER_DETAIL: async ({ commit }, id) => {
    await usersAPI.getUser(id)
      .then((user) => {
        if (user) {
          const { usergroup_roles, ...userData } = user;
          commit('SET_ERROR', null);
          commit('SET_CURRENT_USER', userData);
          commit('SET_CURRENT_USER_ROLES', usergroup_roles);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  SEARCH_USERS_LIST: async ({ state, commit, dispatch }, { text, ordering, unfiltered = false }) => {
    try {
      if (!ordering) {
        ordering = state.currentUsersOrdering;
      }
      if (text) {
        const searched = await dispatch('HANDLE_SEARCH_REQUEST', { text, ordering, unfiltered });
        return searched;
      } else {
        commit('SET_IS_USERSLIST_SEARCHED', {
          isSearched: false,
          text: null
        });
        await dispatch('GET_USERS_LIST', { ...ordering });
        return true;
      }
    } catch (err) {
      console.error(err);
    }
  },

  HANDLE_SEARCH_REQUEST: async ({ state, rootState, getters, commit }, { text, ordering, unfiltered }) => {
    try {

      if (rootState.abortControllers.length > 0) {
        commit('USE_ABORT_CONTROLLER', 'abort_search_users_list', { root: true });
      }

      const controller = new AbortController();
      commit('SET_ABORT_CONTROLLER', {
        id: 'abort_search_users_list',
        controller: controller
      }, { root: true });

      let url;
      if (ordering.field) {
        url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/?ordering=${ordering.direction}${ordering.field}&page=${getters.tablePage}&search=${text}`), DOMAIN);
      } else {
        url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `users/?page=${getters.tablePage}&search=${text}`), DOMAIN);
      }
      const filters = state.currentUsersFilters;
      if (!unfiltered) {
        for (const filter in filters) {
          url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
        }
      }

      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const users = response.data;
        if (users) {
          commit('SET_ERROR', null);
          commit('SET_USERS_LIST', users);
          commit('SET_IS_USERSLIST_SEARCHED', {
            isSearched: true,
            text: text
          });
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_users_list', { root: true });
        return true;
      }
      return false;
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('SET_ERROR', err);
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_users_list', { root: true });
        return true;
      }
      return false;
    }
  },

  UPDATE_USER: async ({ commit }, { id, data }) => {
    await usersAPI.updateUser(id, data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(resp, 'L\'utilisateur a été mis à jour avec succès.');
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  PATCH_USER: async ({ commit }, { id, data }) => {
    await usersAPI.patchUser(id, data).then((resp) => {
      if (resp) {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(resp, "L'utilisateur a été modifié avec succès.");
      }
    }).catch((error) => {
      commit('SET_ERROR', error);
    });
  },

  CREATE_USER: async ({ commit }, data) => {
    await usersAPI.createUser(data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(resp, 'L\'utilisateur a été créé avec succès.');
          commit('SET_LAST_CREATED_USER', resp);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },

  DELETE_USER: async ({ commit }, user) => {
    await usersAPI.deleteUser(user.id)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(resp, `L'utilisateur ${user['Prénom'] || user.first_name} ${user['Nom'] || user.last_name} a été supprimé avec succès.`);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
        ErrorService.onError(error);
        throw error;
      });
  },

  ADD_USER_ROLE: async ({ state, commit, dispatch }, { usergroup, data, type }) => {
    try {
      const response = await usergroupsAPI.addUsergroupMember(usergroup.id, data);
      if (response.status === 201) {
        commit('SET_ERROR', null);
        if (state.currentUser.id) dispatch('GET_USER_DETAIL', state.currentUser.id);
        ErrorService.onSuccess(response, `L'utilisateur à bien été rattaché 
          ${type === 'organisation' ? 'à l\'organisation' : 'au groupe d\'utilisateurs'}
          ${usergroup.display_name}.`
        );
      }
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  UPDATE_USER_ROLE: async ({ state, commit, dispatch }, { usergroup, userId, data, type }) => {
    try {
      const response = await usergroupsAPI.updateUsergroupMember(usergroup.id, userId, data);
      if (response.status === 200) {
        commit('SET_ERROR', null);
        if (state.currentUser.id) dispatch('GET_USER_DETAIL', state.currentUser.id);
        ErrorService.onSuccess(response, `Le rôle de l'utilisateur au sein 
          ${type === 'organisation' ? 'de l\'organisation' : 'du groupe d\'utilisateurs'}
        ${usergroup.display_name} a bien été mis à jour.`);
      }
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  REMOVE_USER_ROLE: async ({ state, commit, dispatch }, { usergroup, userId, type }) => {
    try {
      const response = await usergroupsAPI.removeUsergroupMember(usergroup.id, userId);
      if (response.status === 204) {
        commit('SET_ERROR', null);
        if (state.currentUser.id) dispatch('GET_USER_DETAIL', state.currentUser.id);
        ErrorService.onSuccess(response, `L'utilisateur a bien été supprimé 
          ${type === 'organisation' ? 'de l\'organisation' : 'du groupe d\'utilisateurs'}
        ${usergroup.display_name}.`);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    }
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
