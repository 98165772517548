import axios from 'axios';
import datastoresAPI from '@/api/datastoresAPI.js';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

/**************** STATE *******************/
const state = {
  datastoresList: [],
  selectedDatastore: null,
  datastoreTables: []
};

/**************** GETTERS *****************/
const getters = {

};

/*************** MUTATIONS ****************/
const mutations = {
  SET_DATASTORES_LIST: (state, payload) => {
    state.datastoresList = Object.entries(payload).map(([k, v]) => {
      return {
        label: k,
        url: v
      };
    });
  },

  SET_SELECTED_DATASTORE: (state, payload) => {
    state.selectedDatastore = payload;
  },

  SET_DATASTORE_TABLES: (state, payload) => {
    state.datastoreTables = payload.map(el => {
      return { ...el, label: `${el.schema_name}.${el.table_name}` };
    });
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_DATASTORES_LIST: async ({ commit }, datastoreType) => {
    const datastores = await datastoresAPI.getDatastoreList(datastoreType);
    commit('SET_DATASTORES_LIST', datastores);
  },

  GET_DATASTORE_TABLES: async ({ commit }, tableUrl) => {
    const tables = await datastoresAPI.getTablesList(tableUrl);
    commit('SET_DATASTORE_TABLES', tables);
  },

  SEARCH_DATASTORE_TABLES: async ({ state, dispatch }, text) => {
    if (text) {
      await dispatch('HANDLE_DATASTORE_SEARCH_REQUEST', text);
    } else {
      await dispatch('GET_DATASTORE_TABLES', state.selectedDatastore.url);
    }
  },

  HANDLE_DATASTORE_SEARCH_REQUEST: async ({ state, rootState, commit }, text) => {
    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_datastores', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_datastores',
      controller: controller
    }, { root: true });

    const url = `${state.selectedDatastore.url}?search=${text}`;

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const tables = response.data;
        if (tables) {
          commit('SET_DATASTORE_TABLES', tables);
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_organisations_list', { root: true });
      }
    } catch (err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_organisations_list', { root: true });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
