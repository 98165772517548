import Vue from 'vue';

// Bootstrap installation
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Multiselect installation
import 'vue-multiselect/dist/vue-multiselect.min.css';

// Markdown editor css
import '@toast-ui/editor/dist/toastui-editor.css';

// Custom css
import '@/styles/app.scss';
import '@/styles/app.less';
import '@/styles/app.css';
import 'github-markdown-css/github-markdown-light.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import App from '@/App.vue';
import i18n from './i18n';
import sanitizer from './sanitizer';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

import config from '../public/config.js';
import { capitalizeFirstLetter } from '@/utils';

Vue.mixin({
  methods: {
    isFieldVisible(field, hiddenFields) {
      return !hiddenFields.includes(field);
    },
    isFieldRequired(field, requiredFields) {
      return requiredFields.includes(field) ? 'required' : '';
    },
    getIconPath(iconName) {
      const icon = require.context('@/assets/icons/', false, /\.svg$/);
      return icon(`./${iconName}.svg`);
    }
  }
});

Vue.directive('sanitize', {
  bind: sanitizer
});

Vue.prototype.$config = config;
Vue.prototype.capitalizeFirstLetter = capitalizeFirstLetter;
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');


// Add format function (python style) on JS strings
if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}