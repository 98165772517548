/**************** STATE *******************/
const state = {
  currentFileUpload: null
};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {
  SET_CURRENT_FILE_UPLOAD: (state, payload) => {
    state.currentFileUpload = payload;
  }
};

/**************** ACTIONS *******************/
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};