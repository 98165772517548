import axios from 'axios';
import i18n from '@/i18n';
import { ErrorService } from '@/services/error-service.js';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const ORGANISATION_API_PATH = process.env.VUE_APP_ORGANISATION_API_PATH;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const organisationsAPI = {

  async getOrganisationsList(filters, page = 1) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/?page=${page}`), DOMAIN);
    for (const filter in filters) {
      if (filters[filter] && filters[filter].length) {
        url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async orderOrganisationsList(direction, field, filters, page = 1) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/?ordering=${direction}${field}&page=${page}`), DOMAIN);
    for (const filter in filters) {
      if (filters[filter] && filters[filter].length) {
        url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getOrganisation(id) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createOrganisation(data) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, 'organisations/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async updateOrganisation(id, data) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async setOrganisationThumbnail(id, data) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/${id}/thumbnail/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async setOrganisationAgreement(id, data) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/${id}/agreement/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async removeUserFromOrganisation(usergroupId, userId) {
    try {
      const url = new URL(path.join( `${i18n.locale}${USERGROUP_API_PATH}`, `/user-groups/${usergroupId}/members/${userId}/`), DOMAIN);
      const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 204) {
        return response.data;
      }
      return false;
    } catch (err) {
      ErrorService.onError(err);
      return false;
    }
  },

  async searchOrganisation(text) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `user-groups/?search=${text}`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async deleteOrganisation(id) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/${id}`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async getOrganisationsTypes() {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, 'organisation-types/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getOrganisationsJuridictions() {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, 'jurisdictions/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  }
};

export default organisationsAPI;
