import _ from 'lodash';

/**************** STATE *******************/
const state = {
  currentFormId: null,
  currentForm: {},
  originalFormId: null,
  originalForm: {}
};

/**************** GETTERS *****************/
const getters = {};

/*************** MUTATIONS ****************/
const mutations = {
  SET_ORIGINAL_FORM: (state, payload) => {
    state.originalFormId = payload.id;
    state.originalForm = _.cloneDeep(payload.form);
  },
  SET_CURRENT_FORM: (state, payload) => {
    state.currentFormId = payload.id;
    state.currentForm = payload.form;
  }
};
/**************** ACTIONS *****************/
const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
