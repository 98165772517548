<template>
  <b-modal
    id="confirmation-modal"
    v-model="openModal"
    size="md"
    :title="confirmation.title"
  >
    <div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
      >
        <p>
          {{ confirmation.content }}
        </p>
        <p>{{ $t('modals.confirmation') }}</p>
      </b-overlay>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-secondary"
          class="float-left"
          @click="CLOSE_MODAL('confirmation')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="trigger"
        >
          {{ $t('buttons.confirm') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ConfirmationModal',

  data() {
    return {
      openModal: false,
      loading: false
    }
  },

  computed: {
    ...mapState('modal', [
      'confirmation',
    ])
  },

  watch: {
    'confirmation.open': function(newValue) {
      this.resetModal();
      this.openModal = newValue;
    }
  },

  methods: {
    ...mapMutations('modal', [
      'CLOSE_MODAL',
      'TRIGGER_MODAL'
    ]),
    
    resetModal() {
      this.openModal = false;
      this.loading = false;
    },
    trigger() {
      this.TRIGGER_MODAL({
        modal: 'confirmation'
      });
    }
  }
}
</script>

<style lang="less" scoped>

h2 {
  color: @blue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}

</style>
